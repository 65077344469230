import { queryClient, useClient } from "@/hooks/useClient";
import { useDebuggerStore } from "@/store/debuggerStore";
import dummyPeopleResponse from "@/api/dummyData/v1/people/dummyPeopleResponse.json";
import { components } from "@/openapi-bindings/v2";
import { useSyncOrganization } from "@/store/organizationStore";

const dummyPeople =
  dummyPeopleResponse as unknown as components["schemas"]["Person"][];

export const useGetPeopleQuery = () => {
  const { apiClient } = useClient();
  const isMockPeopleEndpoint = useDebuggerStore(
    (state) => state.isMockPeopleEndpoint,
  );
  const store = useSyncOrganization();

  return apiClient.useQuery(
    "get",
    "/people",
    {
      params: {
        query: {
          organization_id: store.getEffectiveOrganizationId(),
        },
      },
    },
    {
      placeholderData: isMockPeopleEndpoint ? dummyPeople : undefined,
      enabled: !isMockPeopleEndpoint,
      staleTime: 30000,
      cacheTime: 1000 * 60 * 5,
    },
    queryClient,
  );
};
