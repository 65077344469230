"use client";

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { UploadCloud } from "lucide-react";
import { Button } from "@kino/ui";
import { UploadedImage } from "./types";

interface BulkUploaderProps {
  onImagesUploaded: (images: UploadedImage[]) => void;
}

export const BulkUploader: React.FC<BulkUploaderProps> = ({
  onImagesUploaded,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newImages: UploadedImage[] = acceptedFiles.map((file) => ({
        id: Math.random().toString(36).substr(2, 9),
        file,
        preview: URL.createObjectURL(file),
      }));
      onImagesUploaded(newImages);
    },
    [onImagesUploaded],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"],
    },
  });

  return (
    <div
      {...getRootProps()}
      className={`cursor-pointer rounded-lg border-2 border-dashed p-12 text-center transition-colors
        ${
          isDragActive
            ? "border-primary bg-accent/50"
            : "border-border hover:border-primary/50"
        }`}
    >
      <input {...getInputProps()} />
      <UploadCloud className="text-muted-foreground mx-auto mb-4 h-12 w-12" />
      <p className="text-lg font-medium">
        {isDragActive
          ? "Drop the images here..."
          : "Drag & drop images here, or click to select"}
      </p>
      <p className="text-muted-foreground mt-2 text-sm">
        Supports JPEG and PNG files
      </p>
      <Button variant="outline" className="mt-4">
        Select Files
      </Button>
    </div>
  );
};
