import { useLocation, useSearchParams } from "react-router-dom";
import { components } from "@/openapi-bindings/v2";
import { MediaFilter, MediaFilterKey } from "@/types/filters";

type SearchMode = components["schemas"]["SearchMode"];

export type ParamConfig<T> = {
  parse: (value: string | null) => T;
  serialize: (value: T) => string | null;
  default: T;
};

// Explicitly type our param configs
const paramConfigs = {
  idArray: {
    parse: (value: string | null): string[] =>
      value?.split(",").filter(Boolean) ?? [],
    serialize: (value: string[]): string | null =>
      value.length ? value.join(",") : null,
    default: [] as string[],
  },
  searchMode: {
    parse: (value: string | null): SearchMode =>
      value === "transcript" || value === "visual" ? value : "omni",
    serialize: (value: SearchMode): string | null => value,
    default: "omni" as const,
  },
  search: {
    parse: (value: string | null): string => value ?? "",
    serialize: (value: string): string | null => value || null,
    default: "",
  },
  timecodeRange: {
    parse: (value: string | null): [string, string] =>
      (value
        ?.split(",")
        .filter(Boolean)
        .map((v) => v.trim()) as [string, string]) || ["", ""],
    serialize: (value: [string, string]): string | null =>
      value[0] === "" && value[1] === "" ? null : value.join(","),
    default: ["", ""] as [string, string],
  },
} satisfies Record<string, ParamConfig<any>>;

type MediaConfig = {
  [K in MediaFilterKey]: ParamConfig<NonNullable<MediaFilter[K]>>;
};

type SearchConfig = MediaConfig & {
  search: ParamConfig<string>;
  search_mode: ParamConfig<SearchMode>;
};

export const configs = {
  media: {
    person_ids: paramConfigs.idArray,
    tag_ids: paramConfigs.idArray,
    timecode_range: paramConfigs.timecodeRange,
  } satisfies MediaConfig,
  search: {
    person_ids: paramConfigs.idArray,
    tag_ids: paramConfigs.idArray,
    search: paramConfigs.search,
    search_mode: paramConfigs.searchMode,
    timecode_range: paramConfigs.timecodeRange,
  } satisfies SearchConfig,
} as const;

type RouteType = keyof typeof configs;

// Helper to extract the value type from a ParamConfig
type ParamType<T> = T extends ParamConfig<infer U> ? U : never;

// Helper to get the params type from a config
type ParamsType<T> = {
  [K in keyof T]: T[K] extends ParamConfig<any> ? ParamType<T[K]> : never;
};

function parseParams<T extends Record<string, ParamConfig<any>>>(
  config: T,
  searchParams: URLSearchParams,
): ParamsType<T> {
  return Object.entries(config).reduce((acc, [key, paramConfig]) => {
    acc[key as keyof T] = paramConfig.parse(searchParams.get(key));
    return acc;
  }, {} as ParamsType<T>);
}

function serializeParams<T extends Record<string, ParamConfig<any>>>(
  config: T,
  updates: Partial<ParamsType<T>>,
  currentParams: URLSearchParams,
): URLSearchParams {
  const newParams = new URLSearchParams(currentParams);

  Object.entries(updates).forEach(([key, value]) => {
    if (value === undefined) return;

    const paramConfig = config[key as keyof T];
    if (!paramConfig) return;

    const serialized = paramConfig.serialize(value);
    if (serialized === null) {
      newParams.delete(key);
    } else {
      newParams.set(key, serialized);
    }
  });

  return newParams;
}

function useURLParams<T extends RouteType>(routeType: T) {
  const [searchParams, setSearchParams] = useSearchParams();
  const config = configs[routeType];

  return {
    params: parseParams(config, searchParams),
    setParams: (updates: Partial<ParamsType<typeof config>>) => {
      setSearchParams(serializeParams(config, updates, searchParams));
    },
    constructURL: (
      updates: Partial<ParamsType<typeof config>>,
      basePath: string,
    ) => {
      const newParams = serializeParams(config, updates, searchParams);
      const queryString = newParams.toString();
      return `${basePath}${queryString ? `?${queryString}` : ""}`;
    },
  };
}

export function useRouteParams() {
  const { pathname } = useLocation();
  const routeType = pathname.includes("/search")
    ? "search"
    : ("media" as const);
  return useURLParams(routeType);
}

// Type exports
export type MediaParams = ParamsType<MediaConfig>;
export type SearchParams = ParamsType<SearchConfig>;
