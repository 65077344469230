import { createClearable } from "@/utils/zustand/createClearable";
import { persist } from "zustand/middleware";

interface DebuggerStore {
  isMockSearchResults: boolean;
  setIsMockSearchResults: (isMockSearchResults: boolean) => void;
  isMockMediaEndpoint: boolean;
  setIsMockMediaEndpoint: (isMockMediaEndpoint: boolean) => void;
  isMockSeasonStats: boolean;
  setIsMockSeasonStats: (isMockSeasonStats: boolean) => void;
  isMockTagsEndpoint: boolean;
  setIsMockTagsEndpoint: (isMockTagsEndpoint: boolean) => void;
  isMockPeopleEndpoint: boolean;
  setIsMockPeopleEndpoint: (isMockPeopleEndpoint: boolean) => void;
}

export const useDebuggerStore = createClearable([
  "organization",
])<DebuggerStore>()(
  persist(
    (set) => ({
      isMockSearchResults: false,
      setIsMockSearchResults: (isMockSearchResults) =>
        set({ isMockSearchResults }),
      isMockMediaEndpoint: false,
      setIsMockMediaEndpoint: (isMockMediaEndpoint) =>
        set({ isMockMediaEndpoint }),
      isMockSeasonStats: false,
      setIsMockSeasonStats: (isMockSeasonStats) => set({ isMockSeasonStats }),
      isMockTagsEndpoint: false,
      setIsMockTagsEndpoint: (isMockTagsEndpoint) =>
        set({ isMockTagsEndpoint }),
      isMockPeopleEndpoint: false,
      setIsMockPeopleEndpoint: (isMockPeopleEndpoint) =>
        set({ isMockPeopleEndpoint }),
    }),
    {
      name: "debugger-store",
    },
  ),
);
