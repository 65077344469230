import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "@/components/ag-grid/ag-theme-kino.css";
import { useMemo } from "react";
import { ColDef, NestedFieldPaths } from "ag-grid-community";
import { useOrganization } from "@clerk/clerk-react";
import { useGetPeopleQuery } from "@/hooks/useGetPeopleQuery";
import { useActiveServerStore } from "@/store/activeServerStore";
import { components } from "@/openapi-bindings/v2";
import { getSlateData } from "@/utils/multicam";
import { secondsToTimestamp } from "@/utils/time";
import { getFileName } from "@/utils/pathUtils";
import {
  PeopleCellRenderer,
  HasMulticamCellRenderer,
  ThumbnailCellRenderer,
  TagCellRenderer,
  IndexCellRenderer,
} from "@/components/ag-grid/gridCells";
import { ExpandedCellEditor } from "@/components/ag-grid/CellEditor";
import { formatBitRate, formatFrameRate, formatSize } from "@/utils/ffprobe";
import { ContextMenuCell } from "@/components/ag-grid/ContextMenuCell";

type MediaItem = components["schemas"]["MediaItem"];

type OrganizationType =
  | "segpost"
  | "beef"
  | "demo"
  | "sonny"
  | "tmz"
  | "doneanddonedemo";

type GridColumnKey =
  | "index"
  | "thumbnail"
  | "filename"
  | "duration"
  | "people"
  | "shot"
  | "dateShot"
  | "scene"
  | "cameraIdentifier"
  | "hasMulticam"
  | "title"
  | "description"
  | "moments"
  | "transcript"
  | "celebrities_in_video"
  | "celebrities_not_in_video"
  | "num_video_streams"
  | "num_audio_streams"
  | "creation_time"
  | "size"
  | "bit_rate"
  | "framerate"
  | "format_name"
  | "resolution"
  | "codec"
  | "encoder"
  | "location"
  | "eventName"
  | "assetType"
  | "id";

interface OrganizationConfig {
  layout: {
    start: GridColumnKey[];
    middle: GridColumnKey[];
    end: GridColumnKey[];
  };
}

interface KinoGeneratedMetadata {
  title: string;
  moments: string;
  location: string;
  asset_type: string;
  event_name: string;
  transcript: string;
  description: string;
  celebrities_in_video: string;
  celebrities_not_in_video: string;
  id: string;
}

const getKinoMetadata = (
  item: MediaItem | undefined,
): KinoGeneratedMetadata | undefined => {
  if (!item || !item.metadata?.raw_metadata) return undefined;

  const rawMetadata = item.metadata.raw_metadata as {
    // Verbose typing stuff while we finalize openapi.json
    summarize_outputs?: Array<{
      output: KinoGeneratedMetadata;
    }>;
  };

  // First try the new kino_generated format
  if (item.metadata?.kino_generated) {
    // Get the first (and should be only) key's value
    const values = Object.values(item.metadata.kino_generated);
    if (values.length > 0) {
      return values[0] as KinoGeneratedMetadata;
    }
  }

  // Fall back to the old raw_metadata format
  if (!item.metadata?.raw_metadata) return undefined;

  if (!rawMetadata.summarize_outputs) return undefined;

  const outputs = rawMetadata.summarize_outputs;

  if (!outputs.length) return undefined;
  return outputs[0]?.output;
};

const ffProbeColumns: GridColumnKey[] = [
  "num_video_streams",
  "num_audio_streams",
  "creation_time",
  "size",
  "bit_rate",
  "framerate",
  "format_name",
  "resolution",
  "codec",
  "encoder",
];

const organizationConfigs: Record<OrganizationType, OrganizationConfig> = {
  sonny: {
    layout: {
      start: ["thumbnail", "filename", "hasMulticam"],
      middle: [
        "dateShot",
        "shot",
        "scene",
        "cameraIdentifier",
        "duration",
        ...ffProbeColumns,
      ],
      end: [],
    },
  },
  segpost: {
    layout: {
      start: ["thumbnail", "filename"],
      middle: ["duration", "people", ...ffProbeColumns],
      end: [],
    },
  },
  beef: {
    layout: {
      start: ["thumbnail", "filename"],
      middle: [
        "shot",
        "scene",
        "cameraIdentifier",
        "hasMulticam",
        "duration",
        "people",
        ...ffProbeColumns,
        "id",
      ],
      end: [],
    },
  },
  doneanddonedemo: {
    layout: {
      start: ["thumbnail", "title", "description", "moments", "transcript"],
      middle: [
        "duration",
        "celebrities_in_video",
        "celebrities_not_in_video",
        "filename",
        ...ffProbeColumns,
        "id",
      ],
      end: [],
    },
  },
  tmz: {
    layout: {
      start: ["thumbnail", "title"],
      middle: [
        "assetType",
        "filename",
        "eventName",
        "location",
        "description",
        "transcript",
        "moments",
        "duration",
        "celebrities_in_video",
        "celebrities_not_in_video",
        ...ffProbeColumns,
        "id",
      ],
      end: [],
    },
  },
  demo: {
    layout: {
      start: ["thumbnail", "filename", "title"],
      middle: [
        "description",
        "transcript",
        "duration",
        "moments",
        "people",
        ...ffProbeColumns,
        "id",
      ],
      end: [],
    },
  },
};

const baseColumnConfig: Partial<ColDef<MediaItem>> = {
  sortable: true,
  resizable: true,
  suppressSizeToFit: true,
  suppressMovable: true,
  headerClass: "fixed-size-header",
  suppressAutoSize: true,
  onCellContextMenu: (params) => {
    console.log("Right clicked row data:", {
      rowData: params.data,
      column: params.column.getColId(),
      value: params.value,
      node: params.node,
    });
  },
};

const editorColumnConfig: Partial<ColDef<MediaItem>> = {
  editable: true,
  cellEditorPopup: true,
};

const withCellEditor = (config: Partial<ColDef<MediaItem>>) => {
  return {
    ...config,
    editable: true,
    cellEditor: ExpandedCellEditor,
    cellEditorParams: {
      cellRenderer: config.cellRenderer,
      cellRendererParams: config.cellRendererParams,
    },
    cellEditorPopup: true,
  };
};

const createColumn = (
  config: Partial<ColDef<MediaItem>>,
  suppressEditor?: boolean,
): ColDef<MediaItem> => {
  return {
    ...baseColumnConfig,
    ...(!suppressEditor && editorColumnConfig),
    ...(suppressEditor ? config : withCellEditor(config)),
    ...config,
  };
};

const useMediaGrid = ({
  media,
}: {
  media?: components["schemas"]["AllMediaResponse"];
}) => {
  const { organization } = useOrganization();
  const { data: people } = useGetPeopleQuery();
  const { buildServerUrl } = useActiveServerStore();

  const columns: Record<GridColumnKey, ColDef<MediaItem>> = useMemo(
    () => ({
      index: createColumn(
        {
          headerName: "#",
          width: 50,
          minWidth: 50,
          maxWidth: 50,
          pinned: "left",
          valueGetter: (params) =>
            params.node?.rowIndex != null ? params.node.rowIndex + 1 : "",
          sortable: false,
          suppressSizeToFit: true,
          suppressMovable: true,
          suppressMenu: true,
          cellRenderer: IndexCellRenderer,
        },
        true,
      ),
      thumbnail: createColumn(
        {
          headerName: "",
          minWidth: 80,
          width: 150,
          autoHeight: true,
          sortable: false,
          valueGetter: (params) => {
            return buildServerUrl(params.data?.key_thumbnail_path ?? "");
          },
          cellRenderer: ThumbnailCellRenderer,
          cellStyle: ThumbnailCellRenderer.cellStyle,
        },
        true,
      ),
      filename: createColumn({
        headerName: "Filename",
        valueGetter: (params) => {
          return getFileName(params.data?.ffprobe_data?.format?.filename ?? "");
        },
        autoHeight: true,
        wrapText: true,
        minWidth: 80,
        width: 150,
      }),
      duration: createColumn({
        headerName: "Duration",
        valueGetter: (params) => {
          return secondsToTimestamp(
            Number(params.data?.ffprobe_data?.format?.duration ?? 0),
            false,
          );
        },
        minWidth: 80,
        width: 100,
      }),
      people: createColumn({
        headerName: "People",
        autoHeight: true,
        width: 400,
        minWidth: 200,
        sortable: false,
        cellRenderer: PeopleCellRenderer,
        cellStyle: PeopleCellRenderer.cellStyle,
        valueGetter: (params) => {
          return (
            people?.filter((p) => params.data?.person_ids?.includes(p.id)) || []
          );
        },
        cellRendererParams: {
          buildServerUrl,
        },
      }),
      dateShot: createColumn({
        headerName: "Date Shot",
        valueGetter: (params) => {
          return (
            (params.data?.metadata?.processed_metadata as any)?.date_shot ?? ""
          );
        },
        minWidth: 80,
        width: 100,
      }),
      // FFProbe Columns
      num_video_streams: createColumn({
        field: "ffprobe_data.num_video_streams",
        headerName: "Video Streams",
        minWidth: 80,
        width: 100,
      }),

      num_audio_streams: createColumn({
        field: "ffprobe_data.num_audio_streams",
        headerName: "Audio Streams",
        minWidth: 80,
        width: 100,
      }),

      creation_time: createColumn({
        field: "ffprobe_data.format.tags.creation_time",
        headerName: "Creation Time",
        minWidth: 80,
        width: 150,
      }),

      size: createColumn({
        headerName: "Size",
        minWidth: 80,
        width: 100,
        valueGetter: (params) =>
          formatSize(Number(params.data?.ffprobe_data.format.size || 0)),
      }),

      bit_rate: createColumn({
        headerName: "Bit Rate",
        minWidth: 80,
        width: 100,
        valueGetter: (params) =>
          formatBitRate(Number(params.data?.ffprobe_data.format.bit_rate || 0)),
      }),

      framerate: createColumn({
        headerName: "Frame Rate",
        minWidth: 80,
        width: 100,
        valueGetter: (params) => {
          const num = params.data?.ffprobe_data?.framerate_numerator;
          const den = params.data?.ffprobe_data?.framerate_denominator;
          return num && den ? formatFrameRate([num, den]) : "";
        },
      }),

      format_name: createColumn({
        field: "ffprobe_data.format.format_name",
        headerName: "Format",
        minWidth: 80,
        width: 100,
      }),

      resolution: createColumn({
        headerName: "Resolution",
        minWidth: 80,
        width: 120,
        valueGetter: (params) => {
          const videoStream = params.data?.ffprobe_data?.streams.find(
            (s) => s.codec_type === "video",
          );
          return videoStream?.width && videoStream?.height
            ? `${videoStream.width}x${videoStream.height}`
            : "";
        },
      }),
      codec: createColumn({
        headerName: "Codec",
        minWidth: 80,
        width: 100,
        valueGetter: (params) => {
          const videoStream = params.data?.ffprobe_data?.streams.find(
            (s) => s.codec_type === "video",
          );
          return videoStream?.codec_name || "";
        },
      }),
      encoder: createColumn({
        field: "ffprobe_data.format.tags.encoder",
        headerName: "Encoder",
        minWidth: 80,
        width: 150,
      }),
      // Multicam Columns
      shot: createColumn({
        valueGetter: (params) => {
          return getSlateData(params.data)?.shot ?? "";
        },
        headerName: "Shot",
        minWidth: 50,
        width: 80,
      }),
      scene: createColumn({
        valueGetter: (params) => {
          return getSlateData(params.data)?.scene ?? "";
        },
        headerName: "Scene",
        minWidth: 50,
        width: 80,
      }),
      cameraIdentifier: createColumn({
        valueGetter: (params) => {
          return getSlateData(params.data)?.camera_identifier ?? "";
        },
        headerName: "Camera",
        minWidth: 50,
        width: 80,
      }),
      hasMulticam: createColumn({
        valueGetter: (params) => {
          return Boolean(
            (params.data?.metadata?.raw_metadata as any)?.multicam_group,
          );
        },
        headerName: "Multicam",
        minWidth: 50,
        width: 80,
        maxWidth: 100,
        cellStyle: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        cellRenderer: HasMulticamCellRenderer,
      }),
      // Generated Metadata Columns
      title: createColumn({
        valueGetter: (params) => {
          return getKinoMetadata(params.data)?.title ?? "";
        },
        headerName: "Title",
        minWidth: 80,
        autoHeight: true,
        wrapText: true,
      }),
      transcript: createColumn({
        valueGetter: (params) => {
          return getKinoMetadata(params.data)?.transcript ?? "";
        },
        headerName: "Transcript",
        minWidth: 80,
        width: 350,
        wrapText: true,
      }),
      description: createColumn({
        valueGetter: (params) => {
          return getKinoMetadata(params.data)?.description ?? "";
        },
        headerName: "Description",
        minWidth: 80,
        width: 350,
        wrapText: true,
      }),
      moments: createColumn({
        valueGetter: (params) => {
          return getKinoMetadata(params.data)?.moments ?? "";
        },
        headerName: "Moments",
        minWidth: 80,
        width: 200,
        wrapText: true,
      }),
      celebrities_in_video: createColumn({
        valueGetter: (params) => {
          return getKinoMetadata(params.data)?.celebrities_in_video ?? "";
        },
        headerName: "Celebrities In Video",
        minWidth: 80,
        width: 150,
        cellRenderer: TagCellRenderer,
      }),
      celebrities_not_in_video: createColumn({
        headerName: "Celebrities Not In Video",
        valueGetter: (params) => {
          return getKinoMetadata(params.data)?.celebrities_not_in_video ?? "";
        },
        minWidth: 80,
        width: 150,
        cellRenderer: TagCellRenderer,
      }),
      location: createColumn({
        headerName: "Location",
        valueGetter: (params) => {
          return getKinoMetadata(params.data)?.location ?? "";
        },
        width: 200,
        wrapText: true,
      }),
      eventName: createColumn({
        valueGetter: (params) => {
          return getKinoMetadata(params.data)?.event_name ?? "";
        },
        headerName: "Event Name",
        width: 200,
        wrapText: true,
      }),
      assetType: createColumn({
        headerName: "Asset Type",
        valueGetter: (params) => {
          return getKinoMetadata(params.data)?.asset_type ?? "";
        },
        width: 200,
        wrapText: true,
      }),
      id: createColumn({
        field: "id",
        headerName: "ID",
        minWidth: 80,
        width: 100,
        hide: process.env.NODE_ENV !== "development",
        wrapText: true,
      }),
    }),
    [buildServerUrl],
  );

  const columnDefs = useMemo(() => {
    const orgType = organization?.slug as OrganizationType;
    const config =
      orgType && organizationConfigs[orgType]
        ? organizationConfigs[orgType]
        : organizationConfigs.demo;

    const createColumnGroup = (
      keys: GridColumnKey[],
      fixed?: "left" | "right",
    ): ColDef<MediaItem>[] => {
      return keys.map((key) => ({
        ...columns[key],
        pinned: fixed || undefined,
        field: columns[key].field as NestedFieldPaths<MediaItem, any, []>,
      }));
    };

    return [
      columns.index,
      ...createColumnGroup(config.layout.start, "left"),
      ...createColumnGroup(config.layout.middle),
      ...createColumnGroup(config.layout.end, "right"),
    ];
  }, [organization?.slug, columns]);

  return {
    columnDefs,
    rowData: media?.media_items,
  };
};

export default useMediaGrid;
