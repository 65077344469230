import { create } from "zustand";
import { MediaFilterKey } from "@/types/filters";
import { createClearable } from "@/utils/zustand/createClearable";

type BaseSecondaryPanelFilter = { key: string };

type SecondaryPanelFilterTypes = {
  tag_ids: BaseSecondaryPanelFilter & {
    key: "tag_ids";
    tagType: string | null;
  };
} & {
  [K in MediaFilterKey]: BaseSecondaryPanelFilter & { key: K };
};

export type FocusedSecondaryPanelFilter =
  SecondaryPanelFilterTypes[keyof SecondaryPanelFilterTypes];

interface SecondaryFilterPanelState {
  isOpen: boolean;
  focusedFilter: FocusedSecondaryPanelFilter | null;
  openPanel: (filter: FocusedSecondaryPanelFilter) => void;
  closePanel: () => void;
  setFilterState: (
    state: Partial<Omit<SecondaryFilterPanelState, "isOpen">>,
  ) => void;
}

export const useSecondaryFilterPanelStore = createClearable([
  "organization",
])<SecondaryFilterPanelState>()((set) => ({
  isOpen: false,
  focusedFilter: null,
  openPanel: (filter) => set({ isOpen: true, focusedFilter: filter }),
  closePanel: () => set({ isOpen: false, focusedFilter: null }),
  setFilterState: (state) => set((prevState) => ({ ...prevState, ...state })),
}));
