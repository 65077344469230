import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useDebuggerStore } from "./debuggerStore";

interface ActiveServerState {
  activeServerUrl: string | null;
  setActiveServerUrl: (server: string | null) => void;
  serverOverrideUrl: string | null;
  setServerOverrideUrl: (server: string | null) => void;
  getEffectiveServerUrl: () => string;
  buildServerUrl: (path: string) => string;
}

/**
 * Core store that manages server URLs.
 * Uses default zustand create (not createClearable) since this store acts as
 * fundamental state that other stores build upon, similar to authentication state.
 */
const useActiveServerStore = create<ActiveServerState>()(
  persist(
    (set, get) => ({
      activeServerUrl: null,
      setActiveServerUrl: (server) => set({ activeServerUrl: server }),
      serverOverrideUrl: null,
      setServerOverrideUrl: (server) => set({ serverOverrideUrl: server }),
      getEffectiveServerUrl: () => {
        const state = get();
        if (state.serverOverrideUrl) {
          return state.serverOverrideUrl;
        }

        if (state.activeServerUrl) {
          return state.activeServerUrl;
        }

        return "";
      },
      /**
       * Builds a complete server URL by combining the effective server URL with the given path.
       *
       * @param path - The path to append to the server URL.
       * @returns The complete server URL.
       *
       * @example
       * // Assuming the effective server URL is "https://example.com:1923"
       * buildServerUrl("api/data")
       * // Returns: "https://example.com:1923/api/data"
       *
       * buildServerUrl("/api/data")
       * // Returns: "https://example.com:1923/api/data"
       */
      buildServerUrl: (path: string) => {
        const baseUrl = get().getEffectiveServerUrl().replace(/\/$/, "");
        const cleanPath = path.replace(/^\//, "");
        if (useDebuggerStore.getState().isMockSearchResults) {
          return path;
        } else {
          return `${baseUrl}/${cleanPath}`;
        }
      },
    }),
    {
      name: "active-server-storage",
    },
  ),
);

export { useActiveServerStore };
