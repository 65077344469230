import React from "react";
import { ArrayFilterKeys, FilterOption } from "@/types/filters";
import { useFilterState } from "@/hooks/useFilterState";
import { FilterBadgeSkeleton } from "../../FilterBadgeSkeleton";
import { TailwindColor } from "@/types/tailwind";

interface FilterBadgeContentsArrayProps {
  filterOption: FilterOption<any>;
}

const FilterBadgeContentsArray = ({
  filterOption,
}: FilterBadgeContentsArrayProps) => {
  return (
    <div className="flex items-center gap-1 text-xs">
      <div className="h-4 w-4 rounded-full border border-neutral-700">
        {filterOption.icon}
      </div>
      <div>{filterOption.label}</div>
    </div>
  );
};

interface FilterBadgeArrayProps {
  filterKey: ArrayFilterKeys;
  filterOption: FilterOption<any>;
  baseTailwindColor?: TailwindColor;
  displayOnly?: boolean;
  focused?: boolean;
}

const FilterBadgeArray = ({
  filterKey,
  baseTailwindColor,
  displayOnly = false,
  focused = true,
  filterOption,
}: FilterBadgeArrayProps) => {
  const { updateFilterState } = useFilterState();

  return (
    <FilterBadgeSkeleton
      variant={displayOnly ? "displayOnly" : "default"}
      focus={focused ? "focused" : "unfocused"}
      onClose={() => {
        updateFilterState(filterKey, [filterOption.id]);
      }}
      baseColor={baseTailwindColor}
    >
      <FilterBadgeContentsArray filterOption={filterOption} />
    </FilterBadgeSkeleton>
  );
};

export { FilterBadgeArray };
