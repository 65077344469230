import { cn } from "@kino/ui";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import React from "react";
import { components } from "@/openapi-bindings/v2";
import useGetSearchMode from "@/hooks/useGetSearchMode";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import { useRouteParams } from "@/hooks/useURLParams";

interface Tab {
  searchMode: components["schemas"]["SearchMode"];
  displayValue: string;
  numHits?: number;
  icon: React.ReactNode;
}

// Reducing opacity of horizontal lines creates a subtle, beautiful effect.
const CustomOpacityFileTextIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
  >
    <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
    <path d="M14 2v4a2 2 0 0 0 2 2h4" />
    <path d="M10 9H8" opacity="0.5" />
    <path d="M16 13H8" opacity="0.5" />
    <path d="M16 17H8" opacity="0.5" />
  </svg>
);

const CustomOpacityImageIcon = ({ className }: { className?: string }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M9.5 1.5H2.5C1.94772 1.5 1.5 1.94772 1.5 2.5V9.5C1.5 10.0523 1.94772 10.5 2.5 10.5H9.5C10.0523 10.5 10.5 10.0523 10.5 9.5V2.5C10.5 1.94772 10.0523 1.5 9.5 1.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.6"
      d="M4.5 5.5C5.05228 5.5 5.5 5.05228 5.5 4.5C5.5 3.94772 5.05228 3.5 4.5 3.5C3.94772 3.5 3.5 3.94772 3.5 4.5C3.5 5.05228 3.94772 5.5 4.5 5.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity="0.6"
      d="M10.5 7.49985L8.957 5.95685C8.76947 5.76938 8.51516 5.66406 8.25 5.66406C7.98484 5.66406 7.73053 5.76938 7.543 5.95685L3 10.4998"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const OmniSearchResultTypeTabs = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const searchMode = useGetSearchMode();
  const { searchResponse } = useOmniSearch();
  const routeParams = useRouteParams();

  useEffect(() => {
    if (!searchParams.has("search_mode")) {
      searchParams.set("search_mode", "omni");
    }
  }, [location, searchParams]);

  const handleTabChange = (value: components["schemas"]["SearchMode"]) => {
    routeParams.setParams({ search_mode: value });
  };

  const tabs: Tab[] = [
    {
      searchMode: "omni",
      displayValue: "All",
      icon: null,
    },
    {
      searchMode: "transcript",
      displayValue: "Transcript",
      numHits: searchResponse?.transcript_hits?.length ?? 0,
      icon: <CustomOpacityFileTextIcon className="h-3 w-3" />,
    },
    {
      searchMode: "visual",
      displayValue: "Visual",
      numHits: searchResponse?.visual_hits?.length ?? 0,
      icon: <CustomOpacityImageIcon className="h-3 w-3" />,
    },
  ];

  return (
    <div className="flex h-9 min-h-9 items-center gap-0 space-x-3 border-b border-neutral-800 px-2.5">
      {tabs.map((tab) => (
        <button
          key={tab.searchMode}
          onClick={() => handleTabChange(tab.searchMode)}
          className={cn(
            "relative h-full px-1 text-xs font-medium transition-colors",
            searchMode === tab.searchMode
              ? "text-white"
              : "text-neutral-500 hover:text-neutral-300",
          )}
        >
          <div className="flex items-center space-x-1.5">
            {tab.icon && (
              <span
                className={cn(
                  "flex h-4 w-4 items-center justify-center transition-colors",
                  searchMode === tab.searchMode
                    ? "text-indigo-500"
                    : "text-neutral-500 group-hover:text-neutral-300",
                )}
              >
                {tab.icon}
              </span>
            )}
            <span>{tab.displayValue}</span>
            {tab.numHits !== undefined && (
              <span
                className={cn(
                  "flex h-4 items-center font-mono text-[10px] transition-colors",
                  searchMode === tab.searchMode
                    ? "text-neutral-400"
                    : "text-neutral-500 group-hover:text-neutral-300",
                )}
              >
                {tab.numHits}
              </span>
            )}
          </div>
          {searchMode === tab.searchMode && (
            <div className="absolute bottom-0 left-0 h-0.5 w-full bg-indigo-600" />
          )}
        </button>
      ))}
    </div>
  );
};

export default OmniSearchResultTypeTabs;
