import React, { memo, useRef, useEffect, useState } from "react";
import type { ICellEditorParams } from "ag-grid-community";

type CellRendererType = React.ComponentType<{
  value: unknown;
  [key: string]: unknown;
}>;

interface ExpandedCellEditorProps extends ICellEditorParams {
  cellRenderer?: CellRendererType;
  cellRendererParams?: Record<string, unknown>;
}

const DEFAULT_DIMENSION = 100;
const EXPANSION_FACTOR = 1.5;
const MAX_HEIGHT = 400;
const WIDTH_THRESHOLD = 400;

export const ExpandedCellEditor = memo(
  ({
    value,
    cellRenderer: CellRenderer,
    cellRendererParams = {},
    column,
    node,
  }: ExpandedCellEditorProps) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [height, setHeight] = useState(node?.rowHeight ?? DEFAULT_DIMENSION);

    useEffect(() => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        const newHeight = Math.min(textarea.scrollHeight, MAX_HEIGHT);
        setHeight(Math.max(newHeight, node?.rowHeight ?? DEFAULT_DIMENSION));
      }
    }, [value, node?.rowHeight]);

    const baseWidth = column?.getActualWidth() ?? DEFAULT_DIMENSION;

    return (
      <div className="flex items-center justify-center">
        <div
          className="h-fit w-fit overflow-auto border border-neutral-700 bg-neutral-900"
          style={{
            minWidth: baseWidth,
            minHeight: node?.rowHeight ?? DEFAULT_DIMENSION,
            maxWidth: WIDTH_THRESHOLD,
          }}
        >
          {CellRenderer ? (
            <CellRenderer value={value} {...cellRendererParams} />
          ) : (
            <textarea
              ref={textareaRef}
              readOnly
              className="w-full resize-none bg-transparent p-2 leading-normal outline-none"
              style={{
                minWidth: baseWidth,
                height: height,
                maxHeight: MAX_HEIGHT,
              }}
              value={
                typeof value === "object"
                  ? JSON.stringify(value, null, 2)
                  : value
              }
            />
          )}
        </div>
      </div>
    );
  },
);

ExpandedCellEditor.displayName = "ExpandedCellEditor";
