import { create } from "zustand";

export enum InspectorTabValues {
  SearchResultSummary = "search-result-summary",
  SearchResultDetails = "search-result-details",
  Transcript = "transcript",
  Metadata = "metadata",
}

export interface InspectorTab {
  value: string;
  label: string;
  content: React.ReactNode;
  icon: React.ComponentType<any>;
  hidden?: boolean;
}

interface InspectorTabState {
  activeTab: InspectorTabValues;
  setActiveTab: (tab: InspectorTabValues) => void;
}

export const useInspectorTabStore = create<InspectorTabState>()((set) => ({
  activeTab: InspectorTabValues.Transcript,
  setActiveTab: (tab) => set({ activeTab: tab }),
}));
