import { components } from "@/openapi-bindings/v2";
import { HydratedBucket, ParsedQuery } from "@/types";

export function parseSearchQuery(query: string): ParsedQuery {
  const frameRegex = /(\+|-)?\s*frame_id:([\w-]+)/g;
  const frames: { id: string; isPositive: boolean }[] = [];
  let match;
  while ((match = frameRegex.exec(query)) !== null) {
    if (match[2]) {
      frames.push({ id: match[2], isPositive: match[1] !== "-" });
    }
  }
  let queryString = query.replace(frameRegex, "").trim();
  queryString = queryString.startsWith("+")
    ? queryString.slice(1).trim()
    : queryString;

  return { frames, queryString: queryString || undefined };
}

export function stringifyParsedQuery(parsedQuery: ParsedQuery): string {
  let query = "";

  parsedQuery.frames.forEach((frame, index) => {
    if (index !== 0) {
      query += " ";
    }
    query += `${index !== 0 ? (frame.isPositive ? "+" : "-") : ""}frame_id:${
      frame.id
    }`;
  });

  if (parsedQuery.queryString) {
    if (query !== "") {
      query += " + ";
    }
    query += parsedQuery.queryString;
  }

  return query.trim();
}

export const getHitsBySearchMode = (
  searchResponse: components["schemas"]["SearchResponse"],
  searchMode: components["schemas"]["SearchMode"],
): components["schemas"]["Hit"][] => {
  switch (searchMode) {
    case "omni":
      return searchResponse.omni_hits ?? [];
    case "transcript":
      return searchResponse.transcript_hits ?? [];
    case "visual":
      return searchResponse.visual_hits ?? [];
    default:
      return [];
  }
};

export const getMomentsBySearchMode = (
  searchResponse: components["schemas"]["SearchResponse"],
  searchMode: components["schemas"]["SearchMode"],
): components["schemas"]["Moment"][] => {
  const hits = getHitsBySearchMode(searchResponse, searchMode);
  const result = new Array(hits.length);
  let validCount = 0;

  for (const hit of hits) {
    const mediaItem = searchResponse.media_items?.[hit.media_item_id];
    if (mediaItem) {
      const moment = mediaItem.moments.find((m) => m.id === hit.moment_id);
      if (moment) {
        result[validCount++] = moment;
      }
    }
  }

  result.length = validCount;
  return result;
};

export const getHydratedBucketsByAggregationKey = (
  searchResponse: components["schemas"]["SearchResponse"],
  aggregationKey: string,
  searchMode: components["schemas"]["SearchMode"],
): HydratedBucket[] | undefined => {
  const hits = getHitsBySearchMode(searchResponse, searchMode);
  const searchModeAggregations = searchResponse.aggregations?.[searchMode];

  const aggregation = searchModeAggregations?.find(
    (agg) => agg.key === aggregationKey,
  );
  if (aggregationKey === "by_video") {
    // Bucket values are media item ids
    const bucketKeys = aggregation?.values ?? [];
    const hydratedBuckets: HydratedBucket[] = [];

    bucketKeys.forEach((bucketKey) => {
      const mediaItem = searchResponse.media_items?.[bucketKey];
      hydratedBuckets.push({
        key: bucketKey,
        moments: mediaItem?.moments ?? [],
      });
    });

    return hydratedBuckets;
  } else if (aggregationKey === "by_people") {
    // Bucket values are people ids ie: John_Doe, Jane_Doe, etc.
    const mediaItemIds: string[] = hits.map((hit) => hit.media_item_id);
    const mediaItems = mediaItemIds.map(
      (id) => searchResponse.media_items?.[id],
    );
    const bucketKeys: string[] = aggregation?.values ?? [];
    const hydratedBuckets: HydratedBucket[] = [];

    bucketKeys.forEach((bucketKey) => {
      const moments = mediaItems.flatMap((item) => item?.moments ?? []);
      const momentsWithPeopleIds = moments.filter((moment) =>
        moment.person_ids.some((personId) => bucketKey === personId),
      );
      hydratedBuckets.push({
        key: bucketKey,
        moments: momentsWithPeopleIds,
      });
    });

    return hydratedBuckets;
  }
};

export const momentTypeEnumToLabel = (momentType: number | string) => {
  const type = Number(momentType);
  switch (type) {
    case 1:
      return "VIDEO";
    case 2:
      return "SOUND";
    case 3:
      return "IMAGE";
    case 4:
      return "OCR";
    case 5:
      return "TRANSCRIPT";
    default:
      return "Unknown";
  }
};

export const aggregationKeyToLabel = (aggregationKey: string): string => {
  // This is really silly. Must fix lol.
  // Handle tag-specific aggregations
  if (aggregationKey.startsWith("by_tag_")) {
    // Extract the tag name after "by_tag_"
    const tagName = aggregationKey.slice(7);
    return tagName;
  }

  // Handle other common aggregation keys
  if (aggregationKey === "key" || aggregationKey === "values") {
    return aggregationKey;
  }

  // For any other keys, just clean up the format
  const words = aggregationKey
    .replace(/^by_/, "") // Remove leading "by_"
    .split("_"); // Split on underscores

  const label = words
    .map(
      (word: string) =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
    )
    .join(" ");

  return label;
};
