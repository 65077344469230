import React from "react";
import { FaceUploader } from "../components/face-uploader/face-uploader";
import { useGetPeopleQuery } from "@/hooks/useGetPeopleQuery";

const FaceUploaderPage: React.FC = () => {
  const { data: people, isLoading } = useGetPeopleQuery();

  if (isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        Loading...
      </div>
    );
  }

  return (
    <div className="flex h-full w-full overflow-hidden">
      <FaceUploader persons={people ?? []} />
    </div>
  );
};

export default FaceUploaderPage;
