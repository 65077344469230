import React from "react";
import { ScrollArea } from "@kino/ui";
import ContextMenu, { MenuItem } from "../../components/ContextMenu";
import { AvatarIcon, TrashIcon } from "@radix-ui/react-icons";
import { useActiveServerStore } from "@/store/activeServerStore";
import { components } from "@/openapi-bindings/v2";
import { queryClient, useClient } from "@/hooks/useClient";
import { useOrganization } from "@clerk/clerk-react";

interface ReferenceFacesProps {
  selectedPerson: components["schemas"]["Person"] | null;
}

export const ReferenceFaces: React.FC<ReferenceFacesProps> = ({
  selectedPerson,
}) => {
  const { apiClient } = useClient();
  const { organization } = useOrganization();
  const { buildServerUrl } = useActiveServerStore();

  const setAvatarMutation = apiClient.useMutation(
    "put",
    "/person/{person_id}/set-avatar",
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            "get",
            "/people",
            {
              params: {
                query: {
                  organization_id: organization?.id ?? "",
                },
              },
            },
          ],
        });
      },
    },
    queryClient,
  );

  const handleSetAsAvatar = (imagePath: string) => {
    setAvatarMutation.mutateAsync({
      params: {
        path: {
          person_id: selectedPerson?.id ?? "",
        },
        query: {
          organization_id: organization?.id ?? "",
          reference_face_path: imagePath,
        },
      },
    });
  };

  const handleDeleteFace = (imagePath: string) => {
    console.log("Deleting face:", imagePath);
    // This will be connected to the actual delete function later
  };

  if (!selectedPerson) {
    return (
      <div className="text-muted-foreground flex h-full items-center justify-center">
        Select a person to view their reference faces
      </div>
    );
  }

  return (
    <ScrollArea className="h-full">
      <div className="space-y-4 p-4">
        <h2 className="text-lg font-semibold">
          Reference Faces for {selectedPerson.name}
        </h2>
        <div className="grid grid-cols-4 gap-4">
          {selectedPerson.reference_face_paths.map((path, index) => {
            const menuItems: MenuItem[] = [
              {
                label: "Set as Avatar",
                icon: <AvatarIcon />,
                onClick: () => handleSetAsAvatar(path),
              },
              {
                label: "Delete Face",
                icon: <TrashIcon />,
                onClick: () => handleDeleteFace(path),
                addSeparator: true,
              },
            ];

            return (
              <ContextMenu key={index} customMenuItems={menuItems}>
                <div className="border-border group relative overflow-hidden rounded-lg border">
                  <img
                    src={buildServerUrl(path)}
                    alt={`Reference face ${index + 1} for ${selectedPerson.name}`}
                    className="aspect-square w-full object-cover"
                  />
                  {path === selectedPerson.thumbnail_path && (
                    <div className="bg-accent/80 absolute bottom-2 right-2 rounded-full px-2 py-0.5 text-xs">
                      Avatar
                    </div>
                  )}
                </div>
              </ContextMenu>
            );
          })}
        </div>
      </div>
    </ScrollArea>
  );
};
