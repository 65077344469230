import { useLocation, useNavigate } from "react-router-dom";
import {
  RouteParamsHistory,
  useRouteParamsHistoryStore,
} from "@/store/routeParamsHistoryStore";
import { useEffect } from "react";

export const useRouteNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { routeParamsHistory, setRouteParamsHistory } =
    useRouteParamsHistoryStore();

  // Save current route's parameters when they change
  useEffect(() => {
    const currentRoute = location.pathname.substring(
      1,
    ) as keyof RouteParamsHistory;
    if (currentRoute in routeParamsHistory) {
      setRouteParamsHistory(currentRoute, new URLSearchParams(location.search));
    }
  }, [location.search, location.pathname, setRouteParamsHistory]);

  const handleNavigation = (route: string) => {
    // If we have stored params for this route, use them
    const storedParams =
      routeParamsHistory[route as keyof RouteParamsHistory]?.toString();
    navigate(`/${route}${storedParams ? `?${storedParams}` : ""}`);
  };

  return {
    handleNavigation,
    currentPath: location.pathname.substring(1),
  };
};
