import { components } from "@/openapi-bindings/v2";
import { queryClient, useClient } from "@/hooks/useClient";
import { decodedURLSearchParamsToSearchRequest } from "@/hooks/useSearch";
import { useDebuggerStore } from "@/store/debuggerStore";
import dummyResponse from "@/api/dummyData/v2/dummySearchResponse.json";
import { useURLSearchParams } from "./useURLSearchParams";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useSyncOrganization } from "@/store/organizationStore";

export const useOmniSearch = () => {
  const [searchParams] = useSearchParams();
  const { decodeURLSearchParams } = useURLSearchParams();
  const { apiClient } = useClient();
  const isMockSearchResults = useDebuggerStore(
    (state) => state.isMockSearchResults,
  );
  const store = useSyncOrganization();

  // Memoize both the query key and decoded params together
  const { queryKey, decodedParams, searchRequest } = useMemo(() => {
    const { filterState, search, searchMode } =
      decodeURLSearchParams(searchParams);

    const searchRequest = decodedURLSearchParamsToSearchRequest(
      {
        search,
        filterState,
        searchMode,
      },
      store.getEffectiveOrganizationId(),
    );

    return {
      queryKey: [
        "post",
        "/search",
        { body: { filterState, search, searchMode } },
      ],
      decodedParams: { body: searchRequest },
      searchRequest,
    };
  }, [searchParams, store]);

  const {
    data: searchResponse,
    isLoading,
    isSuccess,
    refetch,
  } = apiClient.useQuery(
    "post",
    "/search",
    decodedParams,
    {
      enabled: !isMockSearchResults,
      refetchOnMount: false,
      placeholderData: isMockSearchResults
        ? (dummyResponse as unknown as components["schemas"]["SearchResponse"])
        : undefined,
    },
    queryClient,
  );

  return {
    searchResponse,
    isLoading,
    isSuccess,
    refetch,
    queryKey,
    searchRequest,
  };
};
