import React from "react";
import { SignIn, useClerk } from "@clerk/clerk-react";
import { Button, Card, CardDescription, CardFooter, CardHeader, CardTitle, ScrollArea } from "@kino/ui";
import { Navigate } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";
import Loading from "@/layouts/Loading";
import { useMediaLoggingEnabled } from "@/hooks/useMediaLoggingEnabled";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import { os, todesktop } from "@todesktop/client-core/platform";

export function SignInMobile() {
  return (
    <div className="flex h-screen min-h-screen w-full flex-col items-center justify-center overflow-hidden">
      <ScrollArea className="w-full">
        <div className="flex h-full w-full flex-col items-center justify-center py-8">
          <div className="flex h-fit w-fit flex-col gap-5">
            <div className="flex w-full items-center justify-between">
              <img
                src="/find-the-perfect-shot-in-seconds.svg"
                alt="Find the perfect shot in seconds"
                className="h-10 w-auto"
              />
              <img
                src="/kino-for-enterprise.svg"
                alt="Kino AI for Enterprise"
                className="h-10 w-auto"
              />
            </div>
            <div
              className="relative h-28 rounded-xl"
              style={{
                backgroundImage: "url('/login-promotion.avif')",
                backgroundSize: "cover",
                backgroundPosition: "center 25%",
              }}
            ></div>
            <SignIn path="/" />
          </div>
        </div>
      </ScrollArea>
    </div>
  );
}

export function SignInDesktopLayout({ children }: React.PropsWithChildren) {
  return (
    <div className="flex min-h-screen w-full justify-evenly">
      <div className="flex flex-1 flex-col items-center justify-center bg-neutral-900 p-6">
        {children}
      </div>

      <div
        className="relative flex-1"
        style={{
          backgroundImage: "url('/login-promotion.avif')",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-between p-10">
          <img
            src="/find-the-perfect-shot-in-seconds.svg"
            alt="Find the perfect shot in seconds"
            className="w-56"
          />
          <img
            src="/kino-for-enterprise.svg"
            alt="Kino AI for Enterprise"
            className="w-56"
          />
        </div>
      </div>
    </div>
  )
}

export function SignInDesktop() {
  return(
    <SignInDesktopLayout>
      <SignIn path="/" />
    </SignInDesktopLayout>
  );
}

export function SignInDesktopApp() {
  const Clerk = useClerk();

  const url = Clerk.buildSignInUrl({ 
    signInForceRedirectUrl: "/login/success", 
    signUpForceRedirectUrl: "/login/success" 
  });
  
  return (
    <SignInDesktopLayout>
      <Card>
        <CardHeader>
          <CardTitle>Login to Kino</CardTitle>
          <CardDescription>
            Click below to open an external browser and complete logging in
          </CardDescription>
        </CardHeader>
        <CardFooter>
          <Button variant="kino" onClick={() => os.openURL(url)}>Sign in via external browser</Button>
        </CardFooter>
      </Card>
    </SignInDesktopLayout>
  )
}

export default function SignInPage() {
  const { isSignedIn, isLoaded } = useAuth();
  const enableMediaLogging = useMediaLoggingEnabled();
  const posthog = usePostHog();

  useEffect(() => {
    posthog.capture("signInAttempt", { count: 1 });
  }, []);

  if (!isLoaded) {
    return <Loading className="h-screen" />;
  }

  if (isSignedIn) {
    return <Navigate to={enableMediaLogging ? "/media" : "/search"} replace />;
  }

  if (!isSignedIn && todesktop.isDesktopApp()) {
    return <SignInDesktopApp />;
  }
  
  return (
    <>
      <div className="md:hidden">
        <SignInMobile />
      </div>
      <div className="hidden md:block">
        <SignInDesktop />
      </div>
    </>
  );
}
