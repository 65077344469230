import { cn } from "@/utils/tailwind";
import { useState } from "react";
import {
  PanelResizeHandle as PanelResizeHandleComponent,
  type PanelResizeHandleProps as PanelResizeHandleOriginalProps,
} from "react-resizable-panels";

interface PanelResizeHandleProps extends PanelResizeHandleOriginalProps {
  onDragging?: (isDragging: boolean) => void;
  direction?: "vertical" | "horizontal";
}

const PanelResizeHandle: React.FC<PanelResizeHandleProps> = ({
  onDragging,
  direction = "vertical",
  ...props
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleDragging = (e: boolean) => {
    setIsDragging(e);
    onDragging?.(e);
  };

  const isVertical = direction === "vertical";

  const handleClasses = cn(
    "panel-drag-handle z-0 flex items-center justify-center bg-black transition-all hover:bg-blue-600",
    isVertical
      ? "w-[2px] h-full flex-col gap-1"
      : "h-[2px] w-full flex-row gap-1",
    isDragging && "bg-blue-600",
  );

  const innerHandleClasses = cn(
    "bg-transparent",
    isVertical ? "h-4 w-[2px]" : "h-[2px] w-4",
  );

  return (
    <PanelResizeHandleComponent
      {...props}
      onDragging={handleDragging}
      className={handleClasses}
    >
      <div className={innerHandleClasses} />
    </PanelResizeHandleComponent>
  );
};

export default PanelResizeHandle;
