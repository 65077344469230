import React, { useEffect } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import useClientOs from "../../../../packages/ui/src/utils/useOperatingSystem";
import { useLayoutStore } from "../store/layoutStore";
import { cn } from "@kino/ui";
import { Navigate, Outlet, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { DebuggerPanel } from "./panels/Debugger";
import PanelResizeHandle from "../components/panel/PanelResizeHandle";
import { useOrganization, useUser } from "@clerk/clerk-react";
import { usePostHog } from "posthog-js/react";
import ContextMenu from "@/components/ContextMenu";
import { InspectorPanel } from "@/components/inspector/InspectorPanel";
import Navbar from "@/layouts/panels/Navbar";
import MainPanelHeader from "@/layouts/panels/MainPanelHeader";
import { useInitializeFilters } from "@/hooks/useInitializeFilters";
import { resetStores } from "@/utils/zustand/createClearable";

const KinoWrapper: React.FC = () => {
  const clientOs = useClientOs();
  const { isInspectorOpen, isDebuggerPanelOpen } = useLayoutStore((state) => ({
    isInspectorOpen: state.isInspectorOpen,
    isDebuggerPanelOpen: state.isDebuggerPanelOpen,
  }));

  const { isLoaded, isSignedIn, user } = useUser();
  const { organization, membership } = useOrganization();
  const posthog = usePostHog();
  const location = useLocation();
  const navigate = useNavigate();

  useInitializeFilters();

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      posthog.identify(user.id, {
        email: user.emailAddresses[0]?.emailAddress,
        name: user.fullName,
        avatar: user.imageUrl,
      });

      if (organization) {
        posthog.group("company", organization?.id ?? "", {
          name: organization?.name,
          memberCount: organization?.membersCount,
          memberRole: membership?.role,
        });
      }
    }
  }, [isLoaded, isSignedIn, organization, user, membership]);

  useEffect(() => {
    if (!isLoaded || !isSignedIn) {
      return;
    }

    if (user.organizationMemberships.length === 0) {
      return navigate("/no-access");
    }
  }, [isLoaded, isSignedIn, user?.organizationMemberships.length]);

  useEffect(() => {
    resetStores(["organization"]);
  }, [organization]);

  if (!isLoaded || !isSignedIn) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return (
    <ContextMenu>
      <div
        className={cn(
          "flex w-screen flex-col",
          clientOs == "macOS" &&
            "h-[calc(100vh_-_0px)] max-h-[calc(100vh_-_0px)]",
          clientOs == "windows" &&
            "h-[calc(100vh_-_32px)] max-h-[calc(100vh_-_32px)]",
        )}
      >
        <Navbar />
        <PanelGroup
          autoSaveId="kino-layout-wrapper-autoSaveId"
          direction="horizontal"
          className="z-0 h-full w-full"
        >
          <Panel
            className="flex h-full w-full flex-col"
            minSize={40}
            maxSize={100}
            defaultSize={60}
            id="main-panel"
            order={2}
          >
            <MainPanelHeader />
            <Outlet />
          </Panel>
          {isInspectorOpen && <PanelResizeHandle />}
          {isInspectorOpen && <InspectorPanel />}
          {(import.meta.env.DEV ||
            import.meta.env.VITE_VERCEL_ENV === "preview" ||
            organization?.slug === "kino-ai" ||
            user.emailAddresses.some((email) =>
              email.emailAddress.toLowerCase().endsWith("@trykino.com"),
            )) &&
            isDebuggerPanelOpen && (
              <>
                <PanelResizeHandle />
                <DebuggerPanel />
              </>
            )}
        </PanelGroup>
      </div>
    </ContextMenu>
  );
};

export default KinoWrapper;
