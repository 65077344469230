import { MulticamGroup } from "../../../../packages/player/src/components/ui/sliders";
import { components } from "@/openapi-bindings/v2";
export interface MetadataA24 {
  raw_metadata?: {
    slate_data?: SlateData;
    multicam_group?: MulticamGroup;
  };
  processed_metadata?: {
    date_shot?: string;
  };
}

export interface SlateData {
  scene: string;
  take: string;
  shot: string;
  roll: string;
  crop: boolean;
  director: string;
  camera_identifier: string;
  camera_operator: string;
  timecode_at_action: string;
  tail: boolean;
}

export const getSlateData = (
  item: components["schemas"]["MediaItem"] | undefined,
): SlateData | null => {
  if (!item || !item.metadata?.raw_metadata) return null;
  try {
    const rawMetadata = item.metadata?.raw_metadata;
    if (!rawMetadata) return null;

    const metadata =
      typeof rawMetadata === "string" ? JSON.parse(rawMetadata) : rawMetadata;

    const slateData = metadata.slate_data;

    return slateData;
  } catch (error) {
    console.warn("Error parsing slate data:", error);
    return null;
  }
};

export const QUAD_BOX_PATH =
  "M11.5285 1.1262H7.37462V5.74159H11.99V1.58774C11.99 1.33284 11.7833 1.1262 11.5285 1.1262ZM11.99 6.66466H7.37462V11.28H11.5285C11.7833 11.28 11.99 11.0734 11.99 10.8185V6.66466ZM6.45155 5.74159V1.1262H2.2977C2.0428 1.1262 1.83616 1.33284 1.83616 1.58774V5.74159H6.45155ZM1.83616 6.66466V10.8185C1.83616 11.0734 2.0428 11.28 2.2977 11.28H6.45155V6.66466H1.83616ZM2.2977 0.203125C1.533 0.203125 0.913086 0.823036 0.913086 1.58774V10.8185C0.913086 11.5832 1.533 12.2031 2.2977 12.2031H11.5285C12.2931 12.2031 12.9131 11.5832 12.9131 10.8185V1.58774C12.9131 0.823036 12.2931 0.203125 11.5285 0.203125H2.2977Z";
