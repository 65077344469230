import React, { useRef, useEffect, useCallback } from "react";
import { useCarouselStore } from "@/store/search/carouselStore";
import { debounce } from "lodash";

interface ResultsCarouselProps {
  children: React.ReactNode;
  id: string; // Unique identifier for this carousel instance
}

const ResultsCarousel: React.FC<ResultsCarouselProps> = React.memo(
  ({ children, id }) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const { addCarouselRef, removeCarouselRef } = useCarouselStore();

    // Debounce the scroll handler to prevent excessive updates
    const debouncedAddCarouselRef = useCallback(
      debounce((ref: React.RefObject<HTMLDivElement>, id: string) => {
        addCarouselRef(ref, id);
      }, 100),
      [addCarouselRef],
    );

    useEffect(() => {
      if (scrollContainerRef.current) {
        // Initial registration
        addCarouselRef(scrollContainerRef, id);

        const handleScroll = () => {
          debouncedAddCarouselRef(scrollContainerRef, id);
        };

        scrollContainerRef.current.addEventListener("scroll", handleScroll, {
          passive: true,
        });

        return () => {
          scrollContainerRef.current?.removeEventListener(
            "scroll",
            handleScroll,
          );
          removeCarouselRef(id);
          debouncedAddCarouselRef.cancel();
        };
      }
    }, [addCarouselRef, removeCarouselRef, id, debouncedAddCarouselRef]);

    return (
      <div
        ref={scrollContainerRef}
        className="no-scrollbar flex w-full overflow-x-scroll"
      >
        <div className="flex w-full gap-4">
          {React.Children.map(children, (child) => (
            <div className="w-72 flex-shrink-0">{child}</div>
          ))}
        </div>
      </div>
    );
  },
);

ResultsCarousel.displayName = "ResultsCarousel";

export default ResultsCarousel;
