import { CustomCellRendererProps } from "@ag-grid-community/react";
import { Avatar, AvatarImage, AvatarFallback } from "@kino/ui";
import { components } from "@/openapi-bindings/v2";
import { LayoutGrid, VideoIcon } from "lucide-react";
import { getTagTypeDefinition } from "@/utils/constants/tags";
import { Tag } from "@/types/filters";
import { useState } from "react";

interface PeopleCellProps extends CustomCellRendererProps {
  value: components["schemas"]["Person"];
  buildServerUrl: (path: string) => string;
}

interface TextCellProps extends CustomCellRendererProps {
  value: string;
  allowWrapping?: boolean;
}

interface NumberCellProps extends CustomCellRendererProps {
  value: number;
  formatOptions?: Intl.NumberFormatOptions;
}

// Helper function for initials
const getInitials = (name: string) => {
  return name
    .split(" ")
    .map((part) => part.charAt(0))
    .join("")
    .toUpperCase();
};

interface HasMulticamCellProps extends CustomCellRendererProps {
  value: boolean;
}

export const HasMulticamCellRenderer = ({ value }: HasMulticamCellProps) => {
  return (
    <div className="ag-value">
      {value && <LayoutGrid className="h-4 w-4 text-amber-500" />}
    </div>
  );
};

export const PeopleCellRenderer = ({
  value,
  buildServerUrl,
}: PeopleCellProps) => {
  if (!value || !Array.isArray(value)) return null;

  return (
    <div className="flex flex-wrap gap-2 p-1">
      {value.map((person, index) => (
        <div
          key={`${person.id}-${index}`}
          className="flex items-center gap-1 rounded-md border border-neutral-700 bg-neutral-800/20 px-2 py-1"
        >
          <Avatar className="h-3.5 w-3.5">
            <AvatarImage src={buildServerUrl(person.thumbnail_path || "")} />
            <AvatarFallback className="text-[8px]">
              {getInitials(person.name)}
            </AvatarFallback>
          </Avatar>
          <span className="truncate text-ellipsis whitespace-nowrap text-xs text-neutral-400">
            {person.name}
          </span>
        </div>
      ))}
    </div>
  );
};

PeopleCellRenderer.cellStyle = {
  display: "flex",
  alignItems: "start",
  overflow: "hidden",
  height: "100%",
  flexWrap: "wrap",
  padding: "8px 0",
};

export const TextCellRenderer = ({
  value,
  allowWrapping = false,
}: TextCellProps) => {
  return (
    <div
      className={`p-1 ${
        allowWrapping
          ? "whitespace-normal"
          : "overflow-hidden text-ellipsis whitespace-nowrap"
      }`}
    >
      {value}
    </div>
  );
};

export const NumberCellRenderer = ({
  value,
  formatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  },
}: NumberCellProps) => {
  const formattedValue = new Intl.NumberFormat(undefined, formatOptions).format(
    value,
  );

  return <div className="p-1 text-right">{formattedValue}</div>;
};

interface ThumbnailCellProps extends CustomCellRendererProps {
  value: string | null;
}

export const ThumbnailCellRenderer = ({ value }: ThumbnailCellProps) => {
  const [hasError, setHasError] = useState(false);

  const Fallback = () => (
    <div className="flex aspect-video w-full items-center justify-center rounded bg-neutral-800 p-4 text-neutral-400">
      <VideoIcon size={12} />
    </div>
  );

  if (!value || hasError) {
    return <Fallback />;
  }

  return (
    <img
      src={value}
      alt="thumbnail"
      className="h-full max-h-[400px] w-auto rounded-sm object-cover"
      onError={() => setHasError(true)}
    />
  );
};
ThumbnailCellRenderer.cellStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "12px",
};

interface TagCellProps extends CustomCellRendererProps {
  value: Tag[];
}

export const TagCellRenderer = ({ value }: TagCellProps) => {
  if (!value || !Array.isArray(value) || value.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-2 p-1">
      {value.map((tag, index) => {
        const tagDef = getTagTypeDefinition(tag.tag_type);
        const baseColor = tagDef.baseColor;

        return (
          <div
            key={`${tag.id}-${index}`}
            className={`flex items-center overflow-hidden rounded-md border px-2 py-1 text-xs
              ${
                baseColor === "neutral"
                  ? "border-neutral-700 bg-neutral-800/20 text-neutral-400"
                  : `border-${baseColor}-700/30 bg-${baseColor}-900/10 text-${baseColor}-400`
              }`}
          >
            <span className="truncate text-ellipsis whitespace-nowrap">
              {tag.value}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export const IndexCellRenderer = ({ value }: { value: number }) => {
  return (
    <div className="flex h-full items-center justify-center">
      <span className="text-neutral-600">{value}</span>
    </div>
  );
};
