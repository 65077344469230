import { useEffect } from "react";
import createFetchClient, { Client } from "openapi-fetch";
import createClient, { OpenapiQueryClient } from "openapi-react-query";
import { useAuth } from "@clerk/clerk-react";
import { useActiveServerStore } from "@/store/activeServerStore";
import { QueryClient } from "@tanstack/react-query";
import { useOrganizationWorkspaces } from "./useWorkspaces";
import { useNavigate } from "react-router-dom";
import { paths } from "@/openapi-bindings/v2";

const DEFAULT_TO_HTTPS = import.meta.env.PROD;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const useClient = () => {
  const navigate = useNavigate();
  const { isLoaded, isSignedIn } = useAuth();
  const {
    isLoaded: isWorkspacesLoaded,
    hasWorkspaces,
    workspaces,
  } = useOrganizationWorkspaces();
  const { serverOverrideUrl, setActiveServerUrl } = useActiveServerStore();

  const workspaceUrl =
    isWorkspacesLoaded &&
    hasWorkspaces &&
    workspaces[0] &&
    `${DEFAULT_TO_HTTPS ? "https" : "http"}://${workspaces[0].ip}:${workspaces[0].port}/`;

  const baseUrl = serverOverrideUrl || workspaceUrl || "";

  useEffect(() => {
    if (!isLoaded || !isSignedIn) {
      return;
    }

    if (serverOverrideUrl) {
      return;
    }

    if (!baseUrl) {
      return navigate("/no-access", {
        state: {
          reason: "Your workspace is improperly configured.",
          direction:
            "Please contact Kino to have them check your organization settings.",
        },
      });
    }

    setActiveServerUrl(baseUrl);
  }, [isLoaded, isSignedIn, baseUrl]);

  const fetchClient: Client<paths, `${string}/${string}`> = createFetchClient({
    baseUrl,
  });
  const apiClient: OpenapiQueryClient<paths, `${string}/${string}`> =
    createClient(createFetchClient({ baseUrl }));

  return { fetchClient, apiClient, queryClient };
};
