import { ScrollArea } from "@kino/ui";
import BasicPropertiesCard from "../propertiesCard/BasicPropertiesCard";
import { InspectorCollapsiblePanelSection } from "./InspectorCollapsiblePanelSection";

const InspectorBasicPropertiesCollapsible = () => {
  return (
    <InspectorCollapsiblePanelSection
      label="Properties"
      name="properties"
      defaultOpen
    >
      <ScrollArea className="w-full">
        <BasicPropertiesCard />
      </ScrollArea>
    </InspectorCollapsiblePanelSection>
  );
};

export default InspectorBasicPropertiesCollapsible;
