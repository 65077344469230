import React, { Fragment } from "react";
import {
  NavigationMenu,
  NavigationMenuList,
  NavigationMenuItem,
  Separator,
} from "@kino/ui";
import { MagnifyingGlassIcon, VideoIcon } from "@radix-ui/react-icons";
import { Tab } from "@/components/shared/Tab";
import { useMediaLoggingEnabled } from "@/hooks/useMediaLoggingEnabled";
import { useRouteNavigation } from "@/hooks/useRouteParamsHistory";

const MainPanelHeader: React.FC = () => {
  const { handleNavigation, currentPath } = useRouteNavigation();
  const enableMediaLogging = useMediaLoggingEnabled();

  const menuItems = enableMediaLogging
    ? [
        { icon: <VideoIcon />, label: "Media", value: "media" },
        { icon: <MagnifyingGlassIcon />, label: "Search", value: "search" },
      ]
    : [
        { icon: <MagnifyingGlassIcon />, label: "Search", value: "search" },
        { icon: <VideoIcon />, label: "Media", value: "media" },
      ];

  return (
    <div className="flex h-9 w-full items-center border-b px-2 py-1">
      <NavigationMenu className="flex w-full max-w-none items-center justify-between">
        <NavigationMenuList className="flex items-center justify-start gap-2 py-1">
          {menuItems.map(({ icon, label, value }, i) => (
            <Fragment key={value}>
              <NavigationMenuItem asChild>
                <Tab
                  icon={icon}
                  label={label}
                  value={value}
                  onClick={() => handleNavigation(value)}
                  isActive={currentPath === value}
                />
              </NavigationMenuItem>
              {i !== menuItems.length - 1 && (
                <Separator
                  orientation="vertical"
                  className="h-3 bg-neutral-700"
                />
              )}
            </Fragment>
          ))}
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
};

export default MainPanelHeader;
