import React from "react";
import { ScrollArea } from "@kino/ui";
import { Badge } from "@kino/ui";

import { AddPersonDialog } from "./add-person-dialog";
import { useActiveServerStore } from "@/store/activeServerStore";
import { components } from "@/openapi-bindings/v2";
import { useGetPeopleQuery } from "@/hooks/useGetPeopleQuery";

interface PersonListProps {
  selectedPersonId?: string;
  onSelectPerson: (person: components["schemas"]["Person"]) => void;
  onAddPerson?: (name: string, nickname: string) => void;
}

export const PersonList: React.FC<PersonListProps> = ({
  selectedPersonId,
  onSelectPerson,
  onAddPerson,
}) => {
  const { buildServerUrl } = useActiveServerStore();
  const { data: persons = [] } = useGetPeopleQuery();

  return (
    <ScrollArea className="border-border h-full w-64 border-r">
      <div className="relative space-y-2 p-4">
        <h2 className="mb-4 text-lg font-semibold">People</h2>
        {onAddPerson && <AddPersonDialog onAddPerson={onAddPerson} />}
        {persons.map((person) => (
          <div
            key={person.id}
            className={`hover:bg-accent/50 group flex cursor-pointer items-center justify-between rounded-lg p-2 ${
              selectedPersonId === person.id
                ? "bg-accent/90 ring-primary ring-2"
                : ""
            }`}
            onClick={() =>
              onSelectPerson(person as components["schemas"]["Person"])
            }
          >
            <div className="flex items-center space-x-3">
              <img
                src={buildServerUrl(person?.thumbnail_path ?? "")}
                alt={person.name}
                className="h-10 w-10 rounded-full object-cover"
              />
              <div>
                <p className="font-medium">{person.name}</p>
                <p className="text-muted-foreground text-sm">
                  {person.nickname}
                </p>
              </div>
            </div>
            {person.reference_face_paths.length > 0 && (
              <Badge
                variant="outline"
                className="bg-background/10 hover:bg-accent border-border/40 ml-2 min-w-[28px] justify-center border font-mono text-xs tracking-wider transition-colors"
              >
                {person.reference_face_paths.length}
              </Badge>
            )}
          </div>
        ))}
      </div>
    </ScrollArea>
  );
};

export default PersonList;
