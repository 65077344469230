export const formatBitRate = (bitRate: number) => {
  if (bitRate === null || bitRate === undefined) {
    return "-";
  }

  if (bitRate < 1000) {
    return `${bitRate.toFixed(2)} bps`;
  } else if (bitRate < 1000000) {
    return `${(bitRate / 1000).toFixed(2)} Kbps`;
  } else {
    return `${(bitRate / 1000000).toFixed(2)} Mbps`;
  }
};

export const formatSize = (size: number) => {
  if (size === null || size === undefined) {
    return "-";
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
};

export const formatFrameRate = (input: number | [number, number]) => {
  let fps: number;

  if (Array.isArray(input)) {
    const [num, den] = input;
    fps = num / den;
  } else {
    fps = Math.round((1 / input) * 100) / 100;
  }

  if (Math.abs(fps - 29.97) < 0.01) return "29.97fps (NTSC)";
  if (Math.abs(fps - 25) < 0.01) return "25fps (PAL)";
  if (Math.abs(fps - 24) < 0.01) return "24fps (Film)";
  if (Math.abs(fps - 30) < 0.01) return "30fps";
  if (Math.abs(fps - 60) < 0.01) return "60fps";
  if (Math.abs(fps - 50) < 0.01) return "50fps";

  return `${fps}fps`;
};
