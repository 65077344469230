import ResultsSection from "@/components/omniSearch/ResultsSection";
import OmniSearchCard from "@/components/omniSearch/OmniSearchCard";
import ResultsGrid from "../ResultsGrid";
import { useOmniSearch } from "@/hooks/useOmniSearch";

const AllResultsView = () => {
  const { searchResponse } = useOmniSearch();

  const buckets = {
    visual: searchResponse?.visual_hits,
    transcript: searchResponse?.transcript_hits,
  };

  return (
    <>
      {Object.entries(buckets).map(([key, bucket]) => {
        if (!bucket) return null;
        return (
          <ResultsSection
            key={key}
            title={key.slice(0, 1).toUpperCase() + key.slice(1)}
            numResults={bucket.length}
          >
            <ResultsGrid truncate={true}>
              {bucket.map((hit) => {
                const mediaItem =
                  searchResponse?.media_items?.[hit.media_item_id];
                const moment = mediaItem?.moments.find(
                  (moment) => moment.id === hit.moment_id,
                );
                if (!mediaItem || !moment) return null;
                return (
                  <OmniSearchCard
                    key={hit.id}
                    score={hit.score}
                    moment={moment}
                    searchResponse={searchResponse}
                  />
                );
              })}
            </ResultsGrid>
          </ResultsSection>
        );
      })}
    </>
  );
};

export default AllResultsView;
