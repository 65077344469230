import React from "react";
import OmniSearchResultTypeTabs from "@components/omniSearch/OmniSearchResultTypeTabs";
import { useUser } from "@clerk/clerk-react";
import { CommandMenu } from "@/components/omniSearch/commandMenu/CommandMenu";
import {
  CommandMenuFocusType,
  useCommandMenuFocus,
} from "@/store/commandMenuStore";
import SearchDefaultView from "@/components/omniSearch/searchViews/SearchDefaultView";
import SearchNoResultsView from "@/components/omniSearch/searchViews/SearchNoResultsView";
import SearchLoadingView from "@/components/omniSearch/searchViews/SearchLoadingView";
import SearchResultsView from "@/components/omniSearch/searchViews/SearchResultsView";
import Loading from "@/layouts/Loading";

import { Separator } from "@kino/ui";
import FilterPanel from "@/components/filter/filterPanel/FilterPanel";
import { useLayoutStore } from "@/store/layoutStore";
import { FilterButton } from "@/components/filter/FilterButton";
import { SearchResultsDisplayPopover } from "@/components/omniSearch/display/SearchResultsDisplayPopover";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import { useSearchParams } from "react-router-dom";

const OmniSearch = () => {
  const { isLoaded: isUserLoaded } = useUser();
  const [focused, setFocused] = useCommandMenuFocus(CommandMenuFocusType.Page);
  const { isFilterPanelOpen, set } = useLayoutStore();
  const { searchResponse, isLoading } = useOmniSearch();
  const [searchParams] = useSearchParams();

  if (!isUserLoaded) {
    return <Loading />;
  }

  const hasSearchParams = searchParams.toString().length > 0;

  const renderContent = () => {
    if (!hasSearchParams) {
      return <SearchDefaultView />;
    }

    if (isLoading) {
      return <SearchLoadingView />;
    }

    if (!searchResponse) {
      return <SearchNoResultsView />;
    }

    return (
      <>
        <OmniSearchResultTypeTabs />
        <SearchResultsView />
      </>
    );
  };

  return (
    <div className="flex h-full w-full overflow-hidden">
      <FilterPanel />
      <Separator orientation="vertical" />
      <div className="flex h-full w-full flex-col divide-y overflow-hidden">
        <div className="relative flex h-11 max-h-11 min-h-11 w-full items-center justify-between gap-2 p-2">
          {!isFilterPanelOpen && (
            <FilterButton onClick={() => set("isFilterPanelOpen", true)} />
          )}
          <CommandMenu
            focused={focused}
            setFocused={setFocused}
            focusByDefault={false}
          />
          <SearchResultsDisplayPopover />
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

export default OmniSearch;
