import {
  create as _create,
  StateCreator,
  StoreMutatorIdentifier,
} from "zustand";

type StoreCategory = "layout" | "search" | "organization";
const storeResetFns = new Map<"all" | StoreCategory, Set<() => void>>();

type AnyMiddleware = [StoreMutatorIdentifier, unknown];

const createClearable = (categories: StoreCategory[] = []) => {
  return <T>() => {
    return <Middlewares extends AnyMiddleware[] = []>(
      initializer: StateCreator<T, [], Middlewares> | StateCreator<T>,
    ) => {
      const store = _create<T>()(initializer as StateCreator<T>);
      const initialState = store.getState();
      const allList = storeResetFns.get("all") ?? new Set();
      allList.add(() => store.setState(initialState, true));
      storeResetFns.set("all", allList);

      for (const category of categories) {
        const list = storeResetFns.get(category) ?? new Set();
        list.add(() => store.setState(initialState, true));
        storeResetFns.set(category, list);
      }

      return store;
    };
  };
};

export const resetStores = (categories: Array<"all" | StoreCategory>) => {
  categories.forEach((category) => {
    const resetFns = storeResetFns.get(category);
    resetFns?.forEach((fn) => fn());
  });
};

export { createClearable };
