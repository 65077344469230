import { cn } from "@/utils/tailwind";
import { QuestionMarkIcon } from "@radix-ui/react-icons";
import { useState } from "react";

interface OmniSearchCardThumbnailProps {
  src?: string;
  className?: string;
}

enum ImageLoadingState {
  "loading",
  "loaded",
  "error",
}

const OmniSearchCardThumbnail = ({
  src,
  className,
}: OmniSearchCardThumbnailProps) => {
  const [loadingStatus, setLoadingStatus] = useState<ImageLoadingState>(
    ImageLoadingState.loading,
  );

  if (!src) {
    <div className="flex h-full w-auto items-center justify-center gap-1 bg-gray-100 text-gray-400/70">
      <span className="text-xsm">No preview</span>
    </div>;
  }

  if (loadingStatus === ImageLoadingState.error) {
    return (
      <div
        className={cn(
          "flex h-full w-full items-center justify-center bg-neutral-800 object-cover",
          className,
        )}
      >
        <QuestionMarkIcon className="text-neutral-500" />
      </div>
    );
  }

  return (
    <img
      src={src}
      alt="thumbnail"
      width={200}
      height={200}
      className={cn("h-full w-auto object-cover", className)}
      onError={() => setLoadingStatus(ImageLoadingState.error)}
      onLoad={() => setLoadingStatus(ImageLoadingState.loaded)}
    />
  );
};

export default OmniSearchCardThumbnail;
