import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./ag-theme-kino.css";
import { useCallback, useEffect, useRef } from "react";
import {
  GetContextMenuItemsParams,
  MenuItemDef,
  RowClickedEvent,
} from "ag-grid-community";
import { components } from "@/openapi-bindings/v2";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { getInspectorItemId } from "@/utils/stringUtils";
import useMediaGrid from "@/hooks/useAGMediaGrid";

declare const window: any;

const MediaGrid = ({
  media,
}: {
  media?: components["schemas"]["AllMediaResponse"];
}) => {
  const gridRef = useRef<AgGridReact<components["schemas"]["MediaItem"]>>(null);
  const { columnDefs, rowData } = useMediaGrid({ media });
  const { setCurrentSelection: setSelectedMedia } = useSelectedMediaStore();

  const onRowClicked = useCallback(
    (event: RowClickedEvent<components["schemas"]["MediaItem"]>) => {
      if (!event.data) return;
      setSelectedMedia([
        {
          mediaItem: event.data,
          activeInspectorMoment: undefined,
          id: getInspectorItemId(event.data.id),
        },
      ]);
    },
    [setSelectedMedia],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        gridRef.current?.api.deselectAll();
        gridRef.current?.api.clearFocusedCell();
        setSelectedMedia([]);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [setSelectedMedia]);

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
      const result: (string | MenuItemDef)[] = [
        {
          // custom item
          name: "Alert " + params.value,
          action: () => {
            window.alert("Alerting about " + params.value);
          },
          cssClasses: ["red", "bold"],
        },
        {
          // custom item
          name: "Always Disabled",
          disabled: true,
          tooltip:
            "Very long tooltip, did I mention that I am very long, well I am! Long!  Very Long!",
        },
        {
          name: "Person",
          subMenu: [
            {
              name: "Niall",
              action: () => {
                console.log("Niall was pressed");
              },
            },
            {
              name: "Sean",
              action: () => {
                console.log("Sean was pressed");
              },
            },
            {
              name: "John",
              action: () => {
                console.log("John was pressed");
              },
            },
            {
              name: "Alberto",
              action: () => {
                console.log("Alberto was pressed");
              },
            },
            {
              name: "Tony",
              action: () => {
                console.log("Tony was pressed");
              },
            },
            {
              name: "Andrew",
              action: () => {
                console.log("Andrew was pressed");
              },
            },
            {
              name: "Kev",
              action: () => {
                console.log("Kev was pressed");
              },
            },
            {
              name: "Will",
              action: () => {
                console.log("Will was pressed");
              },
            },
            {
              name: "Armaan",
              action: () => {
                console.log("Armaan was pressed");
              },
            },
          ],
        }, // built in separator
        "separator",
        {
          // custom item
          name: "Windows",
          shortcut: "Alt + W",
          action: () => {
            console.log("Windows Item Selected");
          },
          icon: '<img src="https://www.ag-grid.com/example-assets/skills/windows.png" />',
        },
        {
          // custom item
          name: "Mac",
          shortcut: "Alt + M",
          action: () => {
            console.log("Mac Item Selected");
          },
          icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
        }, // built in separator
        "separator",
        {
          // custom item
          name: "Checked",
          checked: true,
          action: () => {
            console.log("Checked Selected");
          },
          icon: '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
        }, // built in copy item
        "copy",
        "separator",
        "chartRange",
      ];
      return result;
    },
    [window],
  );

  return (
    <div
      className="ag-theme-quartz-dark ag-theme-kino h-full"
      onContextMenu={(e) => {
        e.stopPropagation();
      }}
    >
      <AgGridReact<components["schemas"]["MediaItem"]>
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        enableCellTextSelection={true}
        rowSelection="multiple"
        suppressMovableColumns={true}
        onRowClicked={onRowClicked}
        animateRows={false}
        getRowId={(params) => params.data.id}
        getContextMenuItems={getContextMenuItems}
        cellSelection={true}
        allowContextMenuWithControlKey={true}
      />
    </div>
  );
};

export default MediaGrid;
