import { type PanelProps } from "react-resizable-panels";
import {
  isHTMLVideoElement,
  KinoButtons,
  KinoSliders,
  KinoTimes,
  LocalMediaStorageWithoutTime,
  MediaLoadedMetadataEvent,
  Player,
  PlayerMoment,
  usePrimaryViewportContext,
  Menu,
  useMediaRemote,
  useMediaStore,
} from "@kino/player";
import { SVGProps, useState } from "react";
import { TooltipProvider } from "@kino/ui";
import { useActiveServerStore } from "@/store/activeServerStore";
import PanelHeader from "@/layouts/panels/PanelHeader";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import { getFileName } from "@/utils/pathUtils";
import { PlayerMomentType } from "../../../../../packages/player/src/context/PlayerMomentsContext";
import { cn } from "@/utils/tailwind";
import { CheckIcon } from "@radix-ui/react-icons";
import { Mic2 } from "lucide-react";
import { MetadataA24, QUAD_BOX_PATH } from "@/utils/multicam";
import { MulticamTimelines } from "../../../../../packages/player/src/components/ui/sliders";

const storage = new LocalMediaStorageWithoutTime();

const ViewportPanelEmptyState: React.FC = () => {
  return (
    <div className="flex h-full w-full items-center justify-center text-xs text-neutral-700">
      No Media
    </div>
  );
};

interface ViewportPanelMultipleMediaProps {
  numSelectedMedia: number;
}

const ViewportPanelMultipleMedia: React.FC<ViewportPanelMultipleMediaProps> = ({
  numSelectedMedia,
}) => {
  return (
    <div className="flex h-64 w-full items-center justify-center text-xs text-neutral-700">
      {numSelectedMedia} selected
    </div>
  );
};

const ViewportPanel: React.FC<PanelProps> = (props) => {
  const { playerRef } = usePrimaryViewportContext();
  const remote = useMediaRemote(playerRef);

  const { audioTracks, error } = useMediaStore(playerRef);

  const { selectedMedia } = useSelectedMediaStore((state) => ({
    selectedMedia: state.currentSelection ?? [],
  }));
  const { buildServerUrl } = useActiveServerStore();
  const numSelectedMedia = selectedMedia.length;
  const singleSelection = numSelectedMedia === 1 ? selectedMedia[0] : undefined;

  const [startTime, setStartTime] = useState(0);
  const [isQuadBoxActive, setIsQuadBoxActive] = useState(false);

  const { mediaItem, activeInspectorMoment } = singleSelection ?? {};
  const metadata = (mediaItem?.metadata as unknown as MetadataA24) ?? undefined;
  const moment = activeInspectorMoment?.moment;

  const onLoadedMetadata = (nativeEvent: MediaLoadedMetadataEvent) => {
    const target = nativeEvent.trigger?.target;

    if (isHTMLVideoElement(target)) {
      const element = target;

      element.currentTime = startTime || moment?.start || 0;
    }
  };

  const onMulticam = () => {
    const currentTime = playerRef.current?.currentTime ?? 0;
    const cameras = metadata?.raw_metadata?.multicam_group?.cameras;

    if (!cameras) return;

    if (isQuadBoxActive) {
      const targetCameraOffset =
        Object.entries(cameras).find(
          ([_, camera]) => camera.media_id === mediaItem?.id,
        )?.[1]?.offset ?? 0;

      const adjustedTime = Math.max(0, currentTime - targetCameraOffset);
      setStartTime(adjustedTime);
    } else {
      const currentCameraOffset =
        Object.entries(cameras).find(
          ([_, camera]) => camera.media_id === mediaItem?.id,
        )?.[1]?.offset ?? 0;

      const adjustedTime = currentTime + currentCameraOffset;
      setStartTime(adjustedTime);
    }

    setIsQuadBoxActive((prev) => !prev);
  };

  const multicamGroup = metadata?.raw_metadata?.multicam_group;
  const multicam = metadata?.raw_metadata?.multicam_group !== undefined;
  const multicamManifestPath =
    metadata?.raw_metadata?.multicam_group?.prerendered_hls_manifest;
  const srcFile =
    multicam && isQuadBoxActive
      ? buildServerUrl(multicamManifestPath ?? "")
      : buildServerUrl(mediaItem?.hls_manifest_path ?? "");

  return (
    <div className="flex h-full flex-col">
      <PanelHeader className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-1">Viewport</div>
        {mediaItem && (
          <span className="truncate text-ellipsis text-xs text-neutral-400">
            {getFileName(mediaItem.ffprobe_data?.format?.filename ?? "")}
          </span>
        )}
      </PanelHeader>

      {numSelectedMedia === 0 && <ViewportPanelEmptyState />}
      {numSelectedMedia > 1 && (
        <ViewportPanelMultipleMedia numSelectedMedia={numSelectedMedia} />
      )}
      {selectedMedia && singleSelection && srcFile && (
        <Player
          key={`search-result-panel-player-${singleSelection.id}`}
          storage={storage}
          ref={playerRef}
          src={srcFile}
          useDefaultControls={false}
          className="flex aspect-auto h-full min-h-64 w-full flex-col items-center justify-center bg-black"
          currentTime={startTime || moment?.start}
          duration={
            Number(mediaItem?.ffprobe_data?.format.duration) ?? undefined
          }
          onLoadedMetadata={onLoadedMetadata}
          frameRate={
            mediaItem?.ffprobe_data && [
              mediaItem.ffprobe_data.framerate_numerator ?? 30000,
              mediaItem.ffprobe_data.framerate_denominator ?? 1001,
            ]
          }
          zoom={
            moment?.start != null && moment?.end != null
              ? {
                  start: moment.start - 1,
                  end: moment.end + 1,
                }
              : undefined
          }
          shouldZoomTrackCurrentTime
          customControls={
            <div className="flex w-full flex-col border-b">
              <div className="flex w-full items-center gap-2 border-b bg-neutral-900 p-2">
                <TooltipProvider>
                  <KinoButtons.Play />
                  <div className="no-scrollbar flex shrink items-center gap-2 overflow-x-scroll">
                    <KinoButtons.Generic
                      disabled={!multicam}
                      tooltipContent="Multicam"
                      className={cn(
                        "active:text-yellow-500",
                        isQuadBoxActive && "text-yellow-400",
                      )}
                      onClick={multicam ? onMulticam : () => {}}
                    >
                      <QuadBoxSVG className="h-4 w-4" />
                    </KinoButtons.Generic>
                    {isQuadBoxActive && audioTracks && (
                      <Menu.Root>
                        <Menu.Button>
                          <KinoButtons.Generic>
                            <Mic2 />
                          </KinoButtons.Generic>
                        </Menu.Button>
                        <Menu.Items
                          className="animate-out fade-out slide-out-to-bottom-2 data-[open]:animate-in data-[open]:fade-in data-[open]:slide-in-from-bottom-4 flex h-[var(--menu-height)] max-h-[400px] min-w-[260px] flex-col overflow-y-auto overscroll-y-contain rounded-md border border-white/10 bg-black/95 p-2.5 font-sans text-[15px] font-medium outline-none backdrop-blur-sm transition-[height] duration-300 will-change-[height] data-[resizing]:overflow-hidden"
                          placement="top"
                          offset={0}
                        >
                          {audioTracks.map(({ id, label, selected }, i) => (
                            <Menu.Item
                              onClick={() => remote.changeAudioTrack(i)}
                              key={id}
                            >
                              <span className="h-2 w-2">
                                {selected && <CheckIcon />}
                              </span>
                              {label}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Menu.Root>
                    )}
                    <KinoButtons.ClipIn />
                    <KinoButtons.ClipOut />
                    <KinoButtons.LoopClip />
                    <KinoButtons.JumpToClipIn />
                    <KinoButtons.JumpToClipOut />
                    <KinoButtons.ZoomToMoment />
                  </div>
                  <span className="grow" />
                  <KinoTimes.Times />
                  <KinoButtons.Fullscreen />
                </TooltipProvider>
              </div>
              <div className="flex w-full border">
                {!error && isQuadBoxActive && (
                  <KinoSliders.MulticamTimelineSidebar
                    multicamGroup={multicamGroup}
                  />
                )}
                <div className="flex w-full flex-col border-b">
                  <TooltipProvider>
                    <KinoSliders.TimeWithMoments className="">
                      {isQuadBoxActive && (
                        <MulticamTimelines
                          className="border-t"
                          multicamGroup={multicamGroup}
                        />
                      )}
                      <KinoSliders.Moments className="border-b" />
                    </KinoSliders.TimeWithMoments>
                  </TooltipProvider>
                  <KinoSliders.Window withMiniMap />
                  {moment?.start != null && moment?.end != null && (
                    <PlayerMoment
                      startTime={moment.start}
                      endTime={moment.end}
                      type={PlayerMomentType.Video}
                    />
                  )}
                </div>
              </div>
            </div>
          }
          autoPlay
        />
      )}
    </div>
  );
};

export const QuadBoxSVG = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 13 13" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d={QUAD_BOX_PATH}
      fill="currentColor"
    />
  </svg>
);

export { ViewportPanel };
