import React, { useCallback, useState } from "react";
import {
  MediaErrorDetail,
  Player,
  useMediaPlayer,
  useMediaRemote,
  useMediaStore,
} from "@kino/player";
import { secondsToTimestamp } from "@/utils/time";
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Tooltip,
} from "@kino/ui";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { components } from "@/openapi-bindings/v2";
import { useActiveServerStore } from "@/store/activeServerStore";
import { cn } from "@/utils/tailwind";
import OmniSearchCardThumbnail from "./OmniSearchCardThumbnail";

export const SeekByHoverPlayerGesture: React.FC = () => {
  const player = useMediaPlayer();
  const remote = useMediaRemote();
  const { duration, currentTime, paused } = useMediaStore();

  const [cursorX, setCursorX] = useState(0);
  const [isHovering, setIsHovering] = useState(false);

  const seekOnHover: React.MouseEventHandler = useCallback(
    (e) => {
      const target = e.currentTarget as HTMLDivElement;
      const rect = target.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;

      // Ensure cursor position is within bounds
      if (offsetX >= 0 && offsetX <= target.offsetWidth) {
        const seekTime = (offsetX / target.offsetWidth) * duration;

        setCursorX(offsetX);
        remote.seek(seekTime);
        remote.pause();
      }
    },
    [duration, player, remote],
  );

  const handlePointerLeave = () => {
    setIsHovering(false);
    remote.seek(0);
    remote.pause();

    player?.el?.blur();
  };

  const playingCursorX = (currentTime / duration) * 100;

  return (
    <div
      className="pointer-events-auto absolute inset-0 z-0 block h-full w-full cursor-default"
      onPointerMove={seekOnHover}
      onPointerEnter={() => setIsHovering(true)}
      onPointerLeave={handlePointerLeave}
    >
      {isHovering && (
        <>
          <div
            className="absolute bottom-0 top-0 z-10 w-px bg-red-500"
            style={{ left: cursorX }}
          />
          {!paused && (
            <div
              className="absolute bottom-0 top-0 w-px bg-white/60"
              style={{ left: `${playingCursorX}%` }}
            />
          )}
        </>
      )}
    </div>
  );
};

export const Time = () => {
  const { clipStartTime, currentTime } = useMediaStore();

  return (
    <div className=" text-xsm absolute left-2 top-2 z-50 hidden w-auto rounded bg-black/50 p-1 text-white antialiased backdrop-blur-sm group-hover/player:block">
      {secondsToTimestamp(clipStartTime + currentTime, false)}
    </div>
  );
};

export const TimeCountdown = () => {
  const { duration, currentTime } = useMediaStore();

  return (
    <div className=" text-xsm absolute right-2 top-2 z-50 hidden w-auto rounded bg-black/50 p-1 text-white antialiased backdrop-blur-sm group-hover/player:block">
      {`-${secondsToTimestamp(duration - currentTime, false)}`}
    </div>
  );
};

export const VideoLoadingError: React.FC<{ error: MediaErrorDetail }> = ({
  error,
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className="absolute right-2 top-2 rounded-full bg-gray-500/40 p-1">
          <ExclamationTriangleIcon className="h-3 w-3 text-white" />
        </TooltipTrigger>
        <TooltipContent>{error.message}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

interface OmniSearchCardPlayerProps {
  moment: components["schemas"]["Moment"];
  mediaItem: components["schemas"]["MediaItem"] | null;
}

export const OmniSearchCardPlayer: React.FC<OmniSearchCardPlayerProps> = ({
  moment,
  mediaItem,
}) => {
  const [videoLoadingError, setVideoLoadingError] =
    useState<MediaErrorDetail | null>(null);
  const { buildServerUrl } = useActiveServerStore();
  const [seekCount, setSeekCount] = useState(0);

  return (
    <div className="relative flex aspect-video w-full items-center justify-center overflow-hidden bg-neutral-900 object-contain">
      {!videoLoadingError ? (
        <Player
          src={buildServerUrl(moment.hls_manifest_path)}
          clipStartTime={moment.start ?? 0}
          clipEndTime={moment.end ?? undefined}
          useDefaultControls={false}
          load="eager" // most reliably makes video preview load
          onSeeked={() => {
            // Ensures video is seeked to clipStartTime before showing preview
            // Feels hacky but thus far is the most reliable way to ensure the video is seeked to the start
            if (seekCount === 0) {
              setSeekCount((prev) => prev + 1);
            }
          }}
          duration={
            mediaItem?.ffprobe_data?.format?.duration
              ? Number(mediaItem.ffprobe_data.format.duration)
              : undefined
          }
          frameRate={
            mediaItem?.ffprobe_data?.framerate_numerator &&
            mediaItem?.ffprobe_data?.framerate_denominator
              ? [
                  mediaItem.ffprobe_data.framerate_numerator,
                  mediaItem.ffprobe_data.framerate_denominator,
                ]
              : undefined
          }
          autoPlay={false}
          onError={(error) => {
            console.error(
              "Video loading error code",
              error.code,
              error.message,
            );
            setVideoLoadingError(error);
          }}
          className={cn(
            "group/player transition-opacity duration-200",
            seekCount > 0 ? "opacity-100" : "opacity-0",
          )}
          customGestures={<SeekByHoverPlayerGesture />}
          customControls={
            <>
              <Time />
              <TimeCountdown />
            </>
          }
        />
      ) : (
        <OmniSearchCardThumbnail src={moment.thumbnail_path ?? ""} />
      )}
      {videoLoadingError && <VideoLoadingError error={videoLoadingError} />}
    </div>
  );
};
