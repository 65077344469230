import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Button,
  Input,
  Label,
} from "@kino/ui";
import { Plus } from "lucide-react";
import { useOrganization } from "@clerk/clerk-react";
import { toast } from "sonner";
import { queryClient, useClient } from "@/hooks/useClient";

interface AddPersonDialogProps {
  onAddPerson: (name: string, nickname: string, id?: string) => void;
  referenceFacePath?: string;
  thumbnailPath?: string;
}

export const AddPersonDialog: React.FC<AddPersonDialogProps> = ({
  onAddPerson,
  referenceFacePath,
  thumbnailPath,
}) => {
  const { organization } = useOrganization();
  const { apiClient } = useClient();
  const createPerson = apiClient.useMutation(
    "post",
    "/person",
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["get", "/people"] });
      },
    },
    queryClient,
  );

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [id, setId] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (name.trim()) {
      try {
        await createPerson.mutateAsync({
          body: {
            organization_id: organization?.id ?? "",
            name: name.trim(),
            nickname: nickname.trim(),
            id: id.trim() || undefined,
            reference_face_paths: referenceFacePath ? [referenceFacePath] : [],
            thumbnail_path: thumbnailPath ?? "",
          },
        });

        onAddPerson(name.trim(), nickname.trim(), id.trim() || undefined);
        setName("");
        setNickname("");
        setId("");
        setOpen(false);
        toast.success("Person created successfully");
      } catch (error) {
        toast.error("Failed to create person");
        console.error("Failed to create person:", error);
      }
    }
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="sm"
          className="text-muted-foreground hover:text-foreground absolute right-4 top-4"
        >
          <Plus className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add New Person</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4 pt-4">
          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter name"
              autoFocus
            />
          </div>
          <div className="space-y-2">
            <Label htmlFor="nickname">
              Nickname <span className="text-muted-foreground">(optional)</span>
            </Label>
            <Input
              id="nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              placeholder="Enter nickname"
            />
          </div>
          <div className="space-y-2 opacity-75">
            <Label htmlFor="id">
              ID <span className="text-muted-foreground">(optional)</span>
            </Label>
            <Input
              id="id"
              value={id}
              onChange={(e) => setId(e.target.value)}
              placeholder="Enter ID"
            />
          </div>
          <div className="flex justify-end">
            <Button type="submit" disabled={!name.trim()}>
              Add Person
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
