import { Card } from "@kino/ui";
import OmniSearchCardTranscript from "@components/omniSearch/cardModules/OmniSearchCardTranscript";
import OmniSearchCardRelativeTimeline from "@components/omniSearch/cardModules/OmniSearchCardRelativeTimeline";
import OmniSearchCardPath from "@components/omniSearch/cardModules/OmniSearchCardPath";
import React, { useMemo, type HTMLAttributes } from "react";
import { components } from "@/openapi-bindings/v2";
import { cn } from "@/utils/tailwind";
import { OmniSearchCardPlayer } from "./cardModules/OmniSearchCardPlayer";
import { useSearchDisplayStore } from "@/store/search/searchDisplayStore";
import OmniSearchCardEntities from "./cardModules/OmniSearchCardEntities";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";
import useGetSearchMode from "@/hooks/useGetSearchMode";
import { useLayoutStore } from "@/store/layoutStore";
import { useGetPeopleQuery } from "@/hooks/useGetPeopleQuery";
import { useGetTagsQuery } from "@/hooks/useGetTagsQuery";

interface OmniSearchCardSectionProps extends HTMLAttributes<HTMLDivElement> {}

const OmniSearchCardSection = ({
  children,
  className,
}: OmniSearchCardSectionProps) => {
  return (
    <div className={cn("flex flex-col gap-1 p-2", className)}>{children}</div>
  );
};

interface OmniSearchCardProps {
  moment: components["schemas"]["Moment"];
  isHighlighted?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  searchResponse?: components["schemas"]["SearchResponse"];
  score?: number;
}

const OmniSearchCard = ({
  moment,
  isHighlighted,
  onMouseEnter,
  onMouseLeave,
  searchResponse,
  score,
}: OmniSearchCardProps) => {
  const { data: storedPeople } = useGetPeopleQuery();
  const searchMode = useGetSearchMode();
  const people = useMemo(() => {
    if (!moment.person_ids || !searchResponse?.people) return [];

    return moment.person_ids
      .map((personId) => {
        const person = storedPeople?.find((p) => p.id === personId);
        return person;
      })
      .filter(
        (person): person is components["schemas"]["Person"] =>
          person !== undefined && person !== null,
      );
  }, [moment.person_ids, storedPeople]);

  const { data: storedTags } = useGetTagsQuery();

  const tags = useMemo(() => {
    if (!moment.tag_ids || !storedTags) {
      return [];
    }

    const mappedTags = moment.tag_ids
      .map((tagId) => {
        const tag = storedTags.find((t) => t.id === tagId);
        return tag;
      })
      .filter(
        (tag): tag is components["schemas"]["Tag"] =>
          tag !== undefined && tag !== null,
      );

    return mappedTags;
  }, [moment.tag_ids, storedTags]);

  const mediaItem = searchResponse?.media_items?.[moment.media_item_id];
  const { isInspectorOpen, set } = useLayoutStore((state) => ({
    isInspectorOpen: state.isInspectorOpen,
    set: state.set,
  }));
  const { setSelectedMedia, selectedMedia } = useSelectedMediaStore(
    (state) => ({
      setSelectedMedia: state.setCurrentSelection,
      selectedMedia: state.currentSelection,
    }),
  );

  const handleClick = () => {
    if (mediaItem) {
      setSelectedMedia([
        {
          mediaItem,
          activeInspectorMoment: { moment, searchMode },
          id: `${mediaItem.id}-${moment.id}`,
        },
      ]);

      if (!isInspectorOpen) {
        set("isInspectorOpen", true);
      }
    }
  };

  const isActive = selectedMedia?.find(
    ({ mediaItem: activeInspectorItem, activeInspectorMoment }) =>
      activeInspectorItem.id === mediaItem?.id &&
      activeInspectorMoment?.moment.id === moment.id,
  );
  const { propertyVisibility } = useSearchDisplayStore();

  if (!mediaItem || !searchResponse)
    return <div>Missing media item or search response</div>;

  return (
    <Card
      variant="omniSearchResult"
      className={cn(
        "group/card transition-colors hover:border-neutral-500/60",
        isActive ? "border-indigo-500" : "",
        isHighlighted ? "border-opacity-100" : "border-opacity-[0.65]",
      )}
      key={moment.id}
      onClick={handleClick}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <OmniSearchCardPlayer moment={moment} mediaItem={mediaItem} />
      {propertyVisibility.timeline && (
        <OmniSearchCardRelativeTimeline
          segments={[]}
          markers={[
            {
              percentage: mediaItem?.ffprobe_data?.format?.duration
                ? (100 * (moment.start ?? 0)) /
                  Number(mediaItem.ffprobe_data.format.duration)
                : 0,
            },
          ]}
          duration={
            mediaItem?.ffprobe_data?.format?.duration
              ? Number(mediaItem.ffprobe_data.format.duration)
              : undefined
          }
        />
      )}
      {propertyVisibility.transcript && (
        <OmniSearchCardSection className="h-[65px]">
          <OmniSearchCardTranscript
            text={moment.relevant_transcript_text ?? ""}
            speakerId={undefined} // TODO: Add speakerId once it exists
          />
        </OmniSearchCardSection>
      )}
      {import.meta.env.DEV && propertyVisibility.tags && (
        <OmniSearchCardSection className="h-8 p-0">
          <OmniSearchCardEntities people={people} tags={tags} />
        </OmniSearchCardSection>
      )}
      <OmniSearchCardSection>
        <OmniSearchCardPath
          path={mediaItem?.ffprobe_data?.format?.filename ?? ""}
          moment={moment}
          mediaItem={mediaItem}
        />
      </OmniSearchCardSection>
    </Card>
  );
};

export default OmniSearchCard;
