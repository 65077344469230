import { useClient } from "@/hooks/useClient";
import { useDebuggerStore } from "@/store/debuggerStore";
import dummyAllMediaFilesResponse from "@/api/dummyData/v2/dummyAllMediaFilesResponse.json";
import { components } from "@/openapi-bindings/v2";
import { queryClient } from "@/hooks/useClient";
import { useMediaLoggingEnabled } from "@/hooks/useMediaLoggingEnabled";
import { useRouteParams } from "./useURLParams";
import { useSyncOrganization } from "@/store/organizationStore";

const parseShot = (shot: string) => {
  try {
    const [sceneStr, shotLetter] = shot.split("/");
    return {
      scene: parseInt(sceneStr ?? "0"),
      shot: shotLetter,
    };
  } catch {
    return null;
  }
};

const getMetadata = (item: components["schemas"]["MediaItem"]) => {
  try {
    const rawMetadata = item.metadata?.raw_metadata;
    if (!rawMetadata) return null;

    return typeof rawMetadata === "string"
      ? JSON.parse(rawMetadata)
      : rawMetadata;
  } catch {
    return null;
  }
};

const getSlateData = (item: components["schemas"]["MediaItem"]) => {
  const metadata = getMetadata(item);
  if (!metadata?.slate_data?.shot) return null;
  return parseShot(metadata.slate_data.shot);
};

const hasMulticamGroup = (item: components["schemas"]["MediaItem"]) => {
  const metadata = getMetadata(item);
  return !!metadata?.multicam_group;
};

const compareMedia = (
  a: components["schemas"]["MediaItem"],
  b: components["schemas"]["MediaItem"],
) => {
  // First check for multicam groups - these should always be at the top
  const hasMulticamA = hasMulticamGroup(a);
  const hasMulticamB = hasMulticamGroup(b);

  if (hasMulticamA && !hasMulticamB) return -1;
  if (!hasMulticamA && hasMulticamB) return 1;

  // If both have multicam or both don't, then check slate data
  const slateA = getSlateData(a);
  const slateB = getSlateData(b);

  // If both items have slate data, compare them
  if (slateA && slateB) {
    if (slateA.scene !== slateB.scene) {
      return slateA.scene - slateB.scene;
    }
    if (slateA.shot !== slateB.shot) {
      return (slateA.shot || "").localeCompare(slateB.shot || "");
    }
  }

  // If only one has slate data, prioritize it
  if (slateA) return -1;
  if (slateB) return 1;

  // Fallback to filename sort
  return (a.ffprobe_data.format.filename ?? "").localeCompare(
    b.ffprobe_data.format.filename ?? "",
  );
};

export function useAllMedia() {
  const isMockMediaEndpoint = useDebuggerStore(
    (state) => state.isMockMediaEndpoint,
  );
  const { apiClient } = useClient();
  const enable_media_logging = useMediaLoggingEnabled();
  const routeParams = useRouteParams();
  const store = useSyncOrganization();

  return apiClient.useQuery(
    "get",
    "/all_media",
    {
      params: {
        query: {
          organization_id: store.getEffectiveOrganizationId(),
          limit: 500,
          enable_media_logging,
          person_ids: routeParams.params.person_ids,
          tag_ids: routeParams.params.tag_ids,
        },
      },
    },
    {
      select: (data: components["schemas"]["AllMediaResponse"]) => ({
        ...data,
        media_items: [...data.media_items].sort(compareMedia),
      }),
      enabled: !isMockMediaEndpoint,
      staleTime: 30000,
      cacheTime: 1000 * 60 * 5,
      placeholderData: isMockMediaEndpoint
        ? (dummyAllMediaFilesResponse as unknown as components["schemas"]["AllMediaResponse"])
        : undefined,
    },
    queryClient,
  );
}
