import React from "react";
import { ArrayFilterKeys, FilterCriteriaArray } from "@/types/filters";
import { CommandItem, Checkbox, ScrollArea } from "@kino/ui";
import { useFilterState } from "@/hooks/useFilterState";
import { FilterBadgeArray } from "./FilterBadgeArray";

interface PeopleListProps {
  filterOptions: FilterCriteriaArray<string>;
  filterKey: ArrayFilterKeys;
}

const FilterArrayList = ({ filterOptions, filterKey }: PeopleListProps) => {
  const { updateFilterState, searchState } = useFilterState();
  const filterState = searchState.filterState;
  const handleCheck = (id: string) => {
    updateFilterState(filterKey, [id]);
  };

  return (
    <ScrollArea>
      <div className="flex h-full flex-col gap-2">
        {filterOptions.map((filterOption) => (
          <CommandItem
            key={filterOption.value}
            value={filterOption.value}
            disableDefaultHoverStyle
            className="flex items-center gap-2 p-0 text-neutral-400"
          >
            <Checkbox
              checked={filterState[filterKey]?.some(
                (id) => id === filterOption.id,
              )}
              onCheckedChange={() => handleCheck(filterOption.id)}
            />
            <FilterBadgeArray
              filterKey={filterKey}
              filterOption={filterOption}
              displayOnly={true}
            />
            {/* <div className="truncate text-ellipsis whitespace-nowrap">
              {RELATED_TAGS_STRING}
            </div> */}
          </CommandItem>
        ))}
      </div>
    </ScrollArea>
  );
};
export default FilterArrayList;
