import { ClockIcon, FaceIcon } from "@radix-ui/react-icons";
import { TagIcon } from "@heroicons/react/24/outline";
import { type components } from "@/openapi-bindings/v2";

export const MEDIA_FILTER_KEYS = [
  "person_ids",
  "tag_ids",
  "timecode_range",
] as const;

export type MediaFilterKey = (typeof MEDIA_FILTER_KEYS)[number];

export type MediaFilter = {
  person_ids: string[];
  tag_ids: string[];
  timecode_range?: [string, string] | null;
};

export type Tag = components["schemas"]["Tag"];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExcludeStringLiterals<T> = T extends string ? never : T;
type MediaFilterWithoutNone = ExcludeStringLiterals<MediaFilter>;
type MediaFilterIntersection = UnionToIntersection<MediaFilterWithoutNone>;
type MediaFilterType = MediaFilterIntersection;

export const mediaFilterKeys = Object.keys(
  {} as { [K in MediaFilterKey]: any },
) as Array<MediaFilterKey>;

export function isMediaFilterKey(key: string): key is MediaFilterKey {
  return mediaFilterKeys.includes(key as MediaFilterKey);
}
export type NavigationFilterKeys = MediaFilterKey;

export type SearchResultsFilterKeys = MediaFilterKey;

export const FilterToUrlParamMap: {
  [K in SearchResultsFilterKeys]: string;
} = {
  person_ids: "person_ids",
  tag_ids: "tag_ids",
  timecode_range: "timecode_range",
} as const;

// Has every single possible filter key value pair as optional
export type BaseFilterState = Partial<Pick<MediaFilterType, MediaFilterKey>>;

export type FilterContextType = "Navigation" | "SearchResults";

export type NavigationFilterState = Required<
  Pick<BaseFilterState, NavigationFilterKeys>
>;

export type SearchResultsFilterState = Required<
  Pick<BaseFilterState, SearchResultsFilterKeys>
>;

export const EMPTY_FILTER_STATE: Required<BaseFilterState> = {
  timecode_range: null,
  person_ids: [],
  tag_ids: [],
};

export type FilterOption<T> = {
  id: string;
  value: T;
  label: string;
  icon?: React.ReactNode;
  absPath?: string;
  numResults?: number;
};

export type FilterOptions<T> = FilterOption<T>[];

export type FilterValueType<K extends keyof BaseFilterState> =
  BaseFilterState[K];

export type FilterKey = keyof BaseFilterState;

export type DateRange = [number, number]; // would be nice for this to come from backend

// These represent all the possible filter types
export type FilterCriteriaArray<T> = FilterOption<T>[];
export type FilterCriteriaString = FilterOption<string>;
export type FilterCriteriaDateRange = FilterOption<{
  start: number;
  end: number;
}>;

export type FilterCriteriaTagType = {
  [tagType in Tag["tag_type"]]: {
    tags: Tag[];
    icon?: React.ReactNode;
    label?: string; // for when we want to display a different label than the tag type
  };
};

// prettier-ignore
export type FilterCriteriaMapping = {
  [K in keyof BaseFilterState]: 
    K extends "timecode_range"
      ? FilterCriteriaDateRange
      : K extends "person_ids"
      ? FilterCriteriaArray<string>
      : K extends "tag_ids"
      ? FilterCriteriaTagType
      : never;
};

export type Filter<K extends keyof FilterCriteriaMapping> = {
  label: string;
  criteria: FilterCriteriaMapping[K];
  icon?: React.ReactNode;
  pluralName?: string;
  singularName?: string;
  hiddenFromUI?: boolean;
};

type FilterOptionsMapping = {
  [K in keyof Required<BaseFilterState>]: Filter<K>;
};

type RequiredBaseFilters = {
  [K in keyof Required<BaseFilterState>]: FilterOptionsMapping[K];
};

export type BaseFilters = Partial<RequiredBaseFilters>;

export type NavigationFilters = {
  [K in NavigationFilterKeys]: FilterOptionsMapping[K];
};

export type SearchResultsFilters = {
  [K in SearchResultsFilterKeys]: FilterOptionsMapping[K];
};

/**
 * Create a partial pick of the base filter options with the given exclude keys
 * @param excludeKeys The keys to exclude from the base filter options
 * @returns A partial pick of the base filter options with the given exclude keys
 */
export function createFilteredOptions<T extends keyof RequiredBaseFilters>(
  filterOptions: RequiredBaseFilters,
  excludeKeys: ReadonlyArray<T>,
): Pick<RequiredBaseFilters, Exclude<keyof RequiredBaseFilters, T>> {
  return Object.fromEntries(
    (Object.keys(filterOptions) as Array<keyof RequiredBaseFilters>)
      .filter((key) => !excludeKeys.includes(key as T))
      .map((key) => [key, filterOptions[key]]),
  ) as Pick<RequiredBaseFilters, Exclude<keyof RequiredBaseFilters, T>>;
}

export const EMPTY_BASE_FILTERS: RequiredBaseFilters = {
  timecode_range: {
    label: "Timecode Range",
    criteria: {
      id: "",
      value: { start: 0, end: 0 },
      label: "",
    },
    icon: <ClockIcon className="h-4 w-4" />,
  },
  person_ids: {
    label: "Faces",
    criteria: [],
    icon: <FaceIcon className="h-4 w-4" />,
    pluralName: "faces",
    singularName: "face",
  },
  tag_ids: {
    label: "Tags",
    criteria: {},
    icon: <TagIcon className="h-4 w-4" />,
  },
};

export const EMPTY_SEARCH_RESULTS_FILTER_OPTIONS: SearchResultsFilters =
  createFilteredOptions(EMPTY_BASE_FILTERS, []);

export const EMPTY_NAVIGATION_FILTER_OPTIONS: NavigationFilters =
  createFilteredOptions(EMPTY_BASE_FILTERS, []);

type SpecialTypeKeys = "timecode_range" | "tag_ids";

type UnfilteredArrayFilterKeys = {
  [K in keyof BaseFilterState]: NonNullable<
    BaseFilterState[K]
  > extends Array<any>
    ? Exclude<K, SpecialTypeKeys> // Exclude specific keys manually
    : never;
}[keyof BaseFilterState];

export type ArrayFilterKeys = Exclude<
  UnfilteredArrayFilterKeys,
  SpecialTypeKeys | undefined
>;

const getArrayFilterKeys = (sample: Partial<BaseFilterState>): string[] => {
  return Object.keys(sample).filter((key) =>
    Array.isArray(sample[key as keyof BaseFilterState]),
  );
};

export const arrayFilterKeys = getArrayFilterKeys(
  EMPTY_FILTER_STATE,
) as ArrayFilterKeys[];
