import {
  Form,
  FormField,
  FormItem,
  FormMessage,
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Textarea,
  ClickableIcon,
  cn,
} from "@kino/ui";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { HTMLAttributes, useEffect, useState } from "react";
import {
  HandThumbUpIcon,
  HandThumbDownIcon,
} from "@heroicons/react/24/outline";
import { useClient } from "@/hooks/useClient";
import { components } from "@/openapi-bindings/v2";
import { toast } from "sonner";
import { useOmniSearch } from "@/hooks/useOmniSearch";
import useGetSearchMode from "@/hooks/useGetSearchMode";
import { useActiveBucketStore } from "@/store/search/activeBucketStore";
import { useOrganization } from "@clerk/clerk-react";

const FeedbackSchema = z.object({
  message: z.string().optional(),
});

type TFeedbackForm = z.infer<typeof FeedbackSchema>;

const iconClassName =
  "p-0 text-xs font-normal text-neutral-700 hover:text-neutral-400";

interface SearchResultFeedbackPopoverProps
  extends HTMLAttributes<HTMLDivElement> {
  moment: components["schemas"]["Moment"];
  mediaItem: components["schemas"]["MediaItem"];
}

export default function SearchResultFeedbackPopover({
  moment,
  mediaItem,
  className,
  ...props
}: SearchResultFeedbackPopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isPositive, setIsPositive] = useState<boolean | null>(null);
  const { fetchClient } = useClient();
  const { searchRequest, searchResponse } = useOmniSearch();
  const searchMode = useGetSearchMode();
  const { setActiveMarkerId } = useActiveBucketStore();
  const { organization } = useOrganization();

  const form = useForm<TFeedbackForm>({
    resolver: zodResolver(FeedbackSchema),
    defaultValues: {
      message: "",
    },
  });

  const onSubmit = (data: TFeedbackForm) => {
    if (!searchResponse) return;
    fetchClient
      .POST("/feedback_search", {
        body: {
          search_id: searchRequest.id,
          search_request: searchRequest,
          search_response: searchResponse,
          search_mode: searchMode,
          moment_feedback: {
            moment_id: moment.id,
            media_item_id: mediaItem.id,
            comment: data.message || null,
            relevance_score: isPositive ? 1 : 0,
            organization_id: organization?.id ?? "",
          },
        },
      })
      .then((response) => {
        if (response.error) {
          throw new Error("Failed to submit feedback");
        }
        setIsOpen(false);
        form.reset();
        toast.success("Feedback submitted");
      })
      .catch((error) => {
        console.error("Feedback submission error:", error);
        toast.error("Failed to submit feedback");
      });
  };

  const handleFeedback = (positive: boolean) => {
    setIsPositive(positive);
    setIsOpen(true);
  };

  // Clean up active marker for bucketed results whenever popover opens or closes
  useEffect(() => {
    setActiveMarkerId(null);
  }, [isOpen, setActiveMarkerId]);

  return (
    <div className={cn("flex gap-2", className)} {...props}>
      <ClickableIcon
        Icon={HandThumbUpIcon}
        name="Good result"
        tooltip="Good result"
        className={iconClassName}
        onClick={(e) => {
          e.stopPropagation();
          handleFeedback(true);
        }}
      />
      <ClickableIcon
        Icon={HandThumbDownIcon}
        name="Bad result"
        tooltip="Bad result"
        className={iconClassName}
        onClick={(e) => {
          e.stopPropagation();
          handleFeedback(false);
        }}
      />
      <Dialog open={isOpen} onOpenChange={setIsOpen} modal={true}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader className="text-left">
            <DialogTitle className="text-neutral">Feedback</DialogTitle>
            <DialogDescription>
              Please provide details: (optional)
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(onSubmit)}
              className="space-y-4 text-left"
            >
              <FormField
                control={form.control}
                name="message"
                render={({ field }) => (
                  <FormItem>
                    <Textarea
                      className="h-24 text-xs"
                      {...field}
                      placeholder={
                        isPositive
                          ? "What made this result relevant?"
                          : "Why wasn't this result useful?"
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <p className="mt-4 text-xs text-neutral-600">
                Submitting this report will help Kino AI improve future search
                results across your organization's content.
              </p>
              <DialogFooter className="gap-2">
                <Button
                  variant="outline"
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpen(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" onClick={(e) => e.stopPropagation()}>
                  Submit
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
