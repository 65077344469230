import { TranscriptTextEditor } from "@/components/transcript/TranscriptTextEditor";
import { useRef } from "react";
import { useSelectedMediaStore } from "@/store/selectedMediaStore";

const InspectorTranscript = () => {
  const transcriptSectionRef = useRef<HTMLDivElement>(null);
  const selectedMedia = useSelectedMediaStore(
    (state) => state.currentSelection,
  );
  const numSelectedMedia = selectedMedia?.length ?? 0;
  const singleSelection =
    numSelectedMedia == 1 ? selectedMedia?.[0] : undefined;
  if (!singleSelection) {
    return null;
  }

  return (
    <div className="relative flex h-full w-full">
      <div className="w-full overflow-y-auto" ref={transcriptSectionRef}>
        <TranscriptTextEditor
          activeInspectorItem={singleSelection}
          viewportRef={transcriptSectionRef}
        />
      </div>
    </div>
  );
};

export { InspectorTranscript };
