import { queryClient, useClient } from "@/hooks/useClient";
import { useDebuggerStore } from "@/store/debuggerStore";
import dummyTagsResponse from "@/api/dummyData/v2/tags/dummyTagsResponse.json";
import { components } from "@/openapi-bindings/v2";
import { useSyncOrganization } from "@/store/organizationStore";

const dummyTags =
  dummyTagsResponse as unknown as components["schemas"]["Tag"][];

export const groupTagsByType = (tags: components["schemas"]["Tag"][]) => {
  return tags.reduce(
    (acc, tag) => {
      const tagType = tag.tag_type;
      acc[tagType] = acc[tagType] || [];
      acc[tagType].push(tag);
      return acc;
    },
    {} as Record<string, components["schemas"]["Tag"][]>,
  );
};

export const useGetTagsQuery = () => {
  const { apiClient } = useClient();
  const isMockTagsEndpoint = useDebuggerStore(
    (state) => state.isMockTagsEndpoint,
  );
  const store = useSyncOrganization();

  return apiClient.useQuery(
    "get",
    "/tags",
    {
      params: {
        query: {
          organization_id: store.getEffectiveOrganizationId(),
        },
      },
    },
    {
      placeholderData: isMockTagsEndpoint ? dummyTags : undefined,
      enabled: !isMockTagsEndpoint,
      refetchOnMount: false,
    },
    queryClient,
  );
};
