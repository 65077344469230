import {
  type MediaFilterKey,
  type BaseFilterState,
  MEDIA_FILTER_KEYS,
} from "@/types/filters";
import { FilterUpdateFacade } from "@utils/filter/filterUpdateFacade";
import { type components } from "@/openapi-bindings/v2";
import { useCallback, useMemo } from "react";
import { useGetTagsQuery } from "@/hooks/useGetTagsQuery";
import { useLocation, useSearchParams } from "react-router-dom";
import { useURLSearchParams } from "./useURLSearchParams";
import { useGetPeopleQuery } from "./useGetPeopleQuery";
import {
  configs,
  ParamConfig,
  SearchParams,
  useRouteParams,
} from "./useURLParams";

export const useFilterState = () => {
  const { data: tags } = useGetTagsQuery();
  const { data: people } = useGetPeopleQuery();
  const { pathname } = useLocation();
  const isSearch = pathname.includes("/search");
  const routeType = isSearch ? "search" : "media";
  const { encodeSearchToURLSearchParams, decodeURLSearchParams } =
    useURLSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const routeParams = useRouteParams();

  // TODO: REMOVE RELIANCE ON THIS
  const decodedSearchParams = useMemo(
    () => decodeURLSearchParams(searchParams),
    [searchParams, tags, people],
  );

  const updateFilterState = useCallback(
    <K extends keyof BaseFilterState>(
      filterStateKey: K,
      value: BaseFilterState[K],
      overwrite = false,
    ) => {
      if (value === undefined) {
        console.error("updateFilterState: value is undefined");
        return;
      }

      const filterUpdateFacade = new FilterUpdateFacade();
      const filterState = decodeURLSearchParams(searchParams).filterState;

      const newState = filterUpdateFacade.updateFilter(
        filterStateKey,
        filterState,
        value,
        overwrite,
      );

      if (isSearch) {
        const params = routeParams.params as SearchParams;
        const newSearchParams = encodeSearchToURLSearchParams(
          params.search,
          newState,
          params.search_mode,
        );
        setSearchParams(newSearchParams, { replace: true });
      } else {
        routeParams.setParams({
          person_ids: newState.person_ids,
          tag_ids: newState.tag_ids,
        });
      }
    },
    [routeParams, routeType],
  );

  const clearFilterStateFilterKey = useCallback(
    (filterStateKey: keyof BaseFilterState) => {
      const config = configs[routeType][filterStateKey];

      routeParams.setParams({
        [filterStateKey]: config.default,
      });
    },
    [routeParams, routeType],
  );

  const resetFilterState = useCallback(() => {
    const resetUpdates = Object.fromEntries(
      MEDIA_FILTER_KEYS.map((key) => [key, configs[routeType][key].default]),
    );

    routeParams.setParams(resetUpdates);
  }, [routeParams, isSearch, routeType]);

  const getNumNonEmptyFilters = useCallback(() => {
    return MEDIA_FILTER_KEYS.reduce((count, key) => {
      const value = routeParams.params[key];
      const config = configs[routeType][key] as ParamConfig<typeof value>;

      return config.serialize(value) === null ? count : count + 1;
    }, 0);
  }, [routeParams.params, isSearch]);

  const isFilterEmpty = useCallback(
    (filter: MediaFilterKey): boolean => {
      const value = routeParams.params[filter];
      const routeType = isSearch ? "search" : "media";
      const config = configs[routeType][filter] as ParamConfig<typeof value>;
      return config.serialize(value) === null;
    },
    [routeParams.params, routeType],
  );

  // TODO: Decouple from filter state
  const clearTagByTagType = useCallback(
    (tagType: string) => {
      const filterUpdateFacade = new FilterUpdateFacade();
      const filterState = decodeURLSearchParams(searchParams).filterState;
      const newState = filterUpdateFacade.clearTagByTagType(
        tags ?? [],
        filterState,
        tagType,
      );
      const newSearchParams = encodeSearchToURLSearchParams(
        searchParams.get("search") ?? undefined,
        newState,
        searchParams.get("search_mode") as components["schemas"]["SearchMode"],
      );
      setSearchParams(newSearchParams);
    },
    [tags],
  );

  const getNumAppliedFilters = useCallback(() => {
    const params = routeParams.params;

    return MEDIA_FILTER_KEYS.reduce((count, filterKey) => {
      const value = params[filterKey];
      const config = configs[routeType][filterKey];
      const defaultValue = config.default;

      if (JSON.stringify(value) === JSON.stringify(defaultValue)) {
        return count;
      }

      return (
        count +
        (Array.isArray(value) && !Array.isArray(defaultValue)
          ? value.length
          : 1)
      );
    }, 0);
  }, [routeParams.params, routeType]);

  return {
    updateFilterState,
    clearFilterStateFilterKey,
    resetFilterState,
    getNumNonEmptyFilters,
    isFilterEmpty,
    clearTagByTagType,
    getNumAppliedFilters,
    searchState: decodedSearchParams,
  };
};
