import { createClearable } from "@/utils/zustand/createClearable";

interface InspectorState {
  expandedSections: Record<string, boolean>;
  setSectionExpanded: (section: string, isExpanded: boolean) => void;
}

const useInspectorStore = createClearable(["organization"])<InspectorState>()(
  (set) => ({
    expandedSections: {},
    setSectionExpanded: (section, isExpanded) =>
      set((state) => ({
        expandedSections: { ...state.expandedSections, [section]: isExpanded },
      })),
  }),
);

export { useInspectorStore };
