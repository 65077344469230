import { createClearable } from "@/utils/zustand/createClearable";
import { persist } from "zustand/middleware";

type LayoutKey =
  | "isExplorerOpen"
  | "isInspectorOpen"
  | "isInspectorDisabled"
  | "isDraggingPanelHandle"
  | "isDebuggerPanelOpen"
  | "isExplorerMinified"
  | "isFilterPanelOpen";

type LayoutState = {
  [K in LayoutKey]: boolean;
} & {
  toggle: (key: LayoutKey) => void;
  set: (key: LayoutKey, value: boolean) => void;
};

const initialState: Pick<LayoutState, LayoutKey> = {
  isExplorerOpen: false,
  isInspectorOpen: true,
  isInspectorDisabled: false,
  isDraggingPanelHandle: false,
  isDebuggerPanelOpen: false,
  isExplorerMinified: false,
  isFilterPanelOpen: false,
};

export const useLayoutStore = createClearable(["layout"])<LayoutState>()(
  persist(
    (set) => ({
      ...initialState,
      toggle: (key) => set((state) => ({ [key]: !state[key] })),
      set: (key, value) => set({ [key]: value }),
    }),
    {
      name: "layout-storage",
    },
  ),
);
