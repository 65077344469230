import { SearchResultsFilterState } from "@/types/filters";
import { useSearchParams } from "react-router-dom";
import { SearchState, useURLSearchParams } from "./useURLSearchParams";
import hash from "object-hash";
import { components } from "@/openapi-bindings/v2";
import useGetSearchMode from "./useGetSearchMode";

export const decodedURLSearchParamsToSearchRequest = (
  searchState: SearchState,
  organization_id: string,
) => {
  const { search, filterState } = searchState;

  const searchRequestBody: Omit<components["schemas"]["SearchRequest"], "id"> =
    {
      text: search,
      person_ids: filterState.person_ids,
      tag_ids: filterState.tag_ids,
      timecode_range: filterState.timecode_range,
      search_type: "all",
      return_full_metadata: false,
      organization_id,
      max_results: 20,
    };

  const id = hash(searchRequestBody);

  return { id, ...searchRequestBody };
};

export const useSearch = () => {
  const { encodeSearchToURLSearchParams } = useURLSearchParams();
  const [_, setSearchParams] = useSearchParams();
  const searchMode = useGetSearchMode();

  const handleSearch = async (
    search: string,
    filterState: SearchResultsFilterState,
  ) => {
    const encodedURLSearchParam = encodeSearchToURLSearchParams(
      search,
      filterState,
      searchMode,
    );

    setSearchParams(encodedURLSearchParam);
  };

  return { handleSearch };
};
