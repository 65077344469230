import { CopyButton } from "@/components/CopyButton";
import { useEffect, useState } from "react";
import { useRouteError } from "react-router-dom";
import { SourceMapConsumer, RawSourceMap } from "source-map-js";
import Loading from "../layouts/Loading";
import { cn, ScrollArea } from "@kino/ui";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.min.css";
import "prismjs/components/prism-typescript";

interface GradientOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  direction: "top" | "bottom";
}

export const GradientOverlay = ({
  className,
  direction,
  ...props
}: GradientOverlayProps) => {
  return (
    <div
      className={cn(
        "pointer-events-none absolute left-0 right-0 z-[1] h-[35%] opacity-30",
        direction === "top" &&
          "top-0 rounded-t-lg bg-gradient-to-b from-black to-transparent",
        direction === "bottom" &&
          "bottom-0 rounded-b-lg bg-gradient-to-t from-black to-transparent",
        className,
      )}
      {...props}
    />
  );
};

const fetchSourceMap = async (url: string): Promise<RawSourceMap | null> => {
  try {
    const response = await fetch(url);
    if (response.ok) {
      const sourceMapText = await response.text();
      return JSON.parse(sourceMapText) as RawSourceMap;
    } else {
      console.error(
        `Failed to fetch source map: ${response.status} ${response.statusText}`,
      );
    }
  } catch (error) {
    console.error("Error fetching source map: ", error);
  }
  return null;
};

const remapStackTrace = async (error: Error): Promise<string[]> => {
  const stackLines = error.stack?.split("\n") || [];
  const remappedStack: string[] = [];

  for (const line of stackLines) {
    const match = line.match(/\((http.*?):(\d+):(\d+)\)/);

    if (match) {
      const [_, fileUrl, lineNumber, columnNumber] = match;

      if (fileUrl && lineNumber && columnNumber) {
        const sourceMap = await fetchSourceMap(`${fileUrl}.map`);

        if (sourceMap) {
          try {
            const consumer = new SourceMapConsumer(sourceMap);
            const pos = consumer.originalPositionFor({
              line: parseInt(lineNumber, 10),
              column: parseInt(columnNumber, 10),
            });

            if (pos.source) {
              remappedStack.push(
                `${pos.source}:${pos.line}:${pos.column} (original)`,
              );
            } else {
              console.warn("Remapping failed, using original line");
              remappedStack.push(line);
            }
          } catch (error) {
            console.error("Error parsing source map: ", error);
            remappedStack.push(line);
          }
        } else {
          console.warn("Source map not found, using original stack stace");
          remappedStack.push(line);
        }
      } else {
        console.warn(
          "Line number or column number missing, using original line",
        );
        remappedStack.push(line);
      }
    } else {
      console.warn("No match found for stack trace line, using original line");
      remappedStack.push(line);
    }
  }

  return remappedStack;
};

const RouterErrorBoundary = () => {
  const error = useRouteError();
  const [errorDetails, setErrorDetails] = useState<string | null>(null);

  useEffect(() => {
    const processError = async () => {
      if (error instanceof Error) {
        const remappedStack = await remapStackTrace(error);
        const highlightedStack = Prism.highlight(
          remappedStack.join("\n"),
          Prism.languages.typescript as Prism.Grammar,
          "typescript",
        );
        setErrorDetails(highlightedStack);
      } else {
        setErrorDetails(JSON.stringify(error));
      }
    };
    processError();
  }, [error]);

  if (!errorDetails) {
    return <Loading className="h-screen" />;
  }

  return (
    <div className="flex h-screen flex-col items-center justify-center gap-4 bg-black text-neutral-100">
      <div className="flex flex-col items-center">
        <h1 className="text-lg font-normal">Oops! Something Went Wrong</h1>
        <p className="text-neutral-500">
          Use the code below to diagnose your error.
        </p>
      </div>

      <div className="relative w-3/4 max-w-3xl rounded-lg border border-dashed border-neutral-800 text-sm shadow-lg">
        <div className="relative m-2 rounded-lg border border-neutral-800 bg-neutral-900">
          <div className="absolute right-2 top-2 z-10 mb-4 flex items-end justify-end">
            <CopyButton
              content={errorDetails || ""}
              toastMessage="Copy error message to clipboard"
              tooltipText="Copy Code"
            />
          </div>

          <GradientOverlay direction="top" />

          <ScrollArea className="max-h-84 relative overflow-auto whitespace-pre-wrap bg-transparent p-5 text-left text-neutral-300">
            <pre
              className="langauge-typescript overflow-auto whitespace-pre rounded p-4"
              dangerouslySetInnerHTML={{ __html: errorDetails }}
            />
          </ScrollArea>

          <GradientOverlay direction="bottom" />
        </div>
      </div>
    </div>
  );
};

export default RouterErrorBoundary;
