import React from "react";
import Loading from "@/layouts/Loading";
import { formatStringPlurality } from "@/utils/stringUtils";
import PanelHeader from "@/layouts/panels/PanelHeader";

import { useAllMedia } from "@/hooks/useAllMediaQuery";
import GlideTablePortal from "@/components/glide-table/GlideTablePortal";
import { Separator } from "@kino/ui";
import TagBadge from "@/components/inspector/properties/TagBadge";
import { useMediaLoggingEnabled } from "@/hooks/useMediaLoggingEnabled";
import { FilterButton } from "@/components/filter/FilterButton";
import FilterPanel from "@/components/filter/filterPanel/FilterPanel";
import { useLayoutStore } from "@/store/layoutStore";
import MediaGrid from "@/components/ag-grid/MediaGrid";

const MediaPage: React.FC = () => {
  const { data: media, isLoading } = useAllMedia();
  const { isFilterPanelOpen, set } = useLayoutStore();
  const numRows = media?.media_items.length || 0;
  const isMediaLoggingEnabled = useMediaLoggingEnabled();

  return (
    <div className="flex h-full w-full overflow-hidden">
      <FilterPanel />
      <Separator orientation="vertical" />
      <div className="flex h-full w-full flex-col divide-y">
        <PanelHeader className="h-11 max-h-11 min-h-11 gap-2 border-none">
          {!isFilterPanelOpen && (
            <FilterButton onClick={() => set("isFilterPanelOpen", true)} />
          )}
          <h1 className="text-xs">All Media</h1>
          <span className="text-xs text-neutral-600">
            {numRows} {formatStringPlurality(numRows, "item", "items")}
          </span>
          {isMediaLoggingEnabled && (
            <TagBadge
              variant="tagResult"
              tag={{
                id: "generated",
                value: "All fields are generated by Kino AI",
                tag_type: "GENERATED",
              }}
            />
          )}
        </PanelHeader>
        {isLoading && <Loading />}
        {!isLoading && <MediaGrid media={media} />}
        <GlideTablePortal />
      </div>
    </div>
  );
};

export default MediaPage;
