import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useOrganization } from "@clerk/clerk-react";
import { useEffect } from "react";

interface OrganizationStore {
  organizationOverrideId: string | null;
  organizationOverrideSlug: string | null;
  organizationSlug: string | null;
  currentOrganizationId: string | null;
  setOrganizationOverrideId: (id: string | null) => void;
  setOrganizationOverrideSlug: (slug: string | null) => void;
  setOrganizationSlug: (slug: string | null) => void;
  setCurrentOrganizationId: (id: string | null) => void;
  getEffectiveOrganizationId: () => string;
  getEffectiveOrganizationSlug: () => string | null;
}

const tmzProdOrgId = "org_2oGRFt8QEIXnjYItS6exCHonrsk";
const tmzDevOrgId = "org_2oGoNNJUjWrVqLXHImgSi3pbs0u";

/**
 * Core store that manages organization IDs and slugs.
 * Uses default zustand create (not createClearable) since this store acts as
 * fundamental state that other stores build upon, similar to authentication state.
 */
export const useOrganizationStore = create<OrganizationStore>()(
  persist(
    (set, get) => ({
      organizationOverrideId: null,
      organizationOverrideSlug: null,
      organizationSlug: null,
      currentOrganizationId: null,
      setOrganizationOverrideId: (id) => set({ organizationOverrideId: id }),
      setOrganizationOverrideSlug: (slug) =>
        set({ organizationOverrideSlug: slug }),
      setOrganizationSlug: (slug) => set({ organizationSlug: slug }),
      setCurrentOrganizationId: (id) => set({ currentOrganizationId: id }),
      getEffectiveOrganizationId: () => {
        const state = get();
        // First check override
        if (state.organizationOverrideId) {
          return state.organizationOverrideId;
        }
        // Then check TMZ case
        if (state.currentOrganizationId === tmzProdOrgId) {
          return tmzDevOrgId;
        }
        // Finally fall back to current org
        return state.currentOrganizationId || "";
      },
      getEffectiveOrganizationSlug: () => {
        const state = get();
        // First check override slug
        if (state.organizationOverrideSlug) {
          return state.organizationOverrideSlug;
        }
        return state.organizationSlug;
      },
    }),
    {
      name: "organization-storage",
      partialize: (state) => ({
        organizationOverrideId: state.organizationOverrideId,
        organizationOverrideSlug: state.organizationOverrideSlug,
        organizationSlug: state.organizationSlug,
        currentOrganizationId: state.currentOrganizationId,
      }),
    },
  ),
);

// Hook to sync Clerk organization with store
export const useSyncOrganization = () => {
  const { organization } = useOrganization();
  const store = useOrganizationStore();

  useEffect(() => {
    if (organization?.id && !store.organizationOverrideId) {
      store.setCurrentOrganizationId(organization.id);
    }

    if (organization?.slug && !store.organizationOverrideSlug) {
      store.setOrganizationSlug(organization.slug);
    }
  }, [
    organization?.id,
    organization?.slug,
    store.organizationOverrideId,
    store.organizationOverrideSlug,
  ]);

  return store;
};
