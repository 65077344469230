import React, { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@kino/ui";
import { PersonList } from "./person-list";
import { BulkUploader } from "./bulk-uploader";
import { ImageAssignment } from "./image-assignment";
import { ReferenceFaces } from "./reference-faces";
import { UploadedImage } from "./types";
import { components } from "@/openapi-bindings/v2";

type Person = components["schemas"]["Person"];

interface FaceUploaderProps {
  persons: Person[];
}

export const FaceUploader: React.FC<FaceUploaderProps> = ({
  persons: initialPersons = [],
}) => {
  const [persons, setPersons] = useState(initialPersons);
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const [uploadedImages, setUploadedImages] = useState<UploadedImage[]>([]);

  const handleImagesUploaded = (newImages: UploadedImage[]) => {
    setUploadedImages((prev) => [...prev, ...newImages]);
  };

  const unassignedCount = uploadedImages.filter(
    (img) => !img.assignedPersonId,
  ).length;

  const handleAddPerson = (name: string, nickname: string) => {
    const newPerson: Person = {
      id: name.toLowerCase().replace(/\s+/g, "_"),
      name,
      nickname,
      thumbnail_path: "/placeholder.jpg", // You might want to use a default image
      reference_face_paths: [],
    };
    setPersons((prev) => [...prev, newPerson]);
  };

  return (
    <div className="flex h-full flex-col">
      <div className="border-border bg-background flex items-center justify-center border-b bg-slate-500 px-4 py-2">
        <span className="text-muted-foreground font-mono text-sm font-medium tracking-wider">
          DEVELOPER TOOL
        </span>
      </div>

      <div className="flex flex-1">
        <PersonList
          selectedPersonId={selectedPerson?.id}
          onSelectPerson={setSelectedPerson}
          onAddPerson={handleAddPerson}
        />

        <div className="flex-1 p-6">
          <Tabs defaultValue="upload" className="h-full">
            <TabsList className="border-border mb-4 flex w-full gap-1 rounded-lg border p-1">
              <TabsTrigger
                value="upload"
                className="data-[state=active]:bg-accent data-[state=active]:text-accent-foreground flex-1 rounded-md data-[state=active]:shadow-sm"
              >
                Bulk Upload
              </TabsTrigger>
              <TabsTrigger
                value="assign"
                className="data-[state=active]:bg-accent data-[state=active]:text-accent-foreground flex-1 rounded-md data-[state=active]:shadow-sm"
              >
                Assign Images
                {unassignedCount > 0 && (
                  <span className="text-muted-foreground bg-accent/50 ml-2 rounded-full px-2 py-0.5 text-xs">
                    {unassignedCount}
                  </span>
                )}
              </TabsTrigger>
              <TabsTrigger
                value="reference"
                className="data-[state=active]:bg-accent data-[state=active]:text-accent-foreground flex-1 rounded-md data-[state=active]:shadow-sm"
              >
                Reference Faces
              </TabsTrigger>
            </TabsList>

            {/* Make all TabsContent use the same height calculation */}
            <div className="h-[calc(100%-56px)]">
              <TabsContent
                value="upload"
                className="h-full data-[state=inactive]:hidden"
              >
                <BulkUploader onImagesUploaded={handleImagesUploaded} />
              </TabsContent>

              <TabsContent
                value="assign"
                className="h-full data-[state=inactive]:hidden"
              >
                <ImageAssignment
                  images={uploadedImages}
                  selectedPerson={selectedPerson}
                  onAssign={(imageId, personId) => {
                    setUploadedImages((prev) =>
                      prev.map((img) =>
                        img.id === imageId
                          ? { ...img, assignedPersonId: personId }
                          : img,
                      ),
                    );
                  }}
                />
              </TabsContent>

              <TabsContent
                value="reference"
                className="h-full data-[state=inactive]:hidden"
              >
                <ReferenceFaces selectedPerson={selectedPerson} />
              </TabsContent>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
